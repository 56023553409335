<template>
    <div class="content">
        <div class="team-img" :style="backgroundStyles(image)">

        </div>
        <div class="team-text">
            <div class="team">
                <div class="title">
                    PrimDent
                </div>
                <div class="desc-div">
                    <div class="desc" v-html="$t('about_bottom_desc')">
                    </div>
                    <div class="btn">
                        <ButtonComponent color="green" :text="$t('about_bottom_button')" data-bs-toggle="modal" data-bs-target="#signUp" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/common/ButtonComponent.vue'
//import ButtonComponent from '@/common/ButtonComponent.vue'
import { Image } from '@/plugins/backend/images'
import { useWindowSize } from 'vue-window-size'
export default {
    name: "AboutThird",
    components: {
        ButtonComponent
    },
    created() {
        if (this.windowWidth > 450) {
            Image.getImage('about_image5').then(data => {
                this.image = this.$store+data;
            })
            }   
        else {
            Image.getImage('about_image5_mobile').then(data => {
                this.image = this.$store+data;
            })
        }
    },
    data() {
        return {
            image:'',
            title: 'Serviciile Noastre',
            desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat',
        }
    },
    setup() {
        const { width, height } = useWindowSize();
        return {
            windowWidth: width,
            windowHeight: height
        }
    },
    methods: {
        backgroundStyles(image) {
            return {
                'background-image': `url(${image})`,
            }
        }
    },
}
</script>

<style scoped>
        .team-img {
            height: 60vh;
            width:100vw;
            background-image: url('../assets/img/person.png');
            background-position:center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .team-text {
            height: 34.16vh;
            justify-content: center;
            color:#1C4437;

            font-family: 'Montserrat';
        }
        .team {
            margin: max(62px,6.9vh) 0  auto 0;
        }
        .title {
            font-size: max(31px,7.5vw);
            text-align: center;
        }
        .desc-div {
            text-align: center;
            display: block;
            line-height: 2.1vw;
            text-align: left;
        }
        .desc {
            text-align: center;
            margin: auto;
            font-size: max(3.14vw,13px);
            line-height: max(20px,2.23vh);
            margin-top: max(15px,1.67vh);
        }
        .btn {
            width:20%;
            display: flex;
            justify-content: center;
            margin: auto;

        }
    @media screen and (min-width:920px) {
        .team-img {
            height: 75vh;
            width:100vw;
            background-position:center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .team-text {
            height: 25vh;
            display: flex;
            justify-content: center;
            color:#1C4437;
            font-weight: 600;
            font-family: 'Montserrat';
        }
        .team {
            width: 70%;
            margin: auto;
        }
        .title {
            font-size: 3.37vw;
            text-align: left;
        }
        .desc-div {
            font-size: 1.17vw;
            display: flex;
            line-height: 2.1vw;
            text-align: left;

        }
        .desc {
            width: 100%;
            font-size: 1.17vw;
            line-height: max(28px,3.65vh);
            text-align: left;
        }
        .btn {
            width:20%;
        }
    }
</style>

