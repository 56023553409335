<template>
<full-page :options="options">

    <MenuComponent id="menu" :whiter="true"/>
    <SignUp id="signup"  />
    <StickyContacts  id="sticky" />
    <FullscreenView class="section"  id="estetica" btnText="Programează-te" :title="$t('estetica_main_title')" adress="estetica" :nr="1" />
    <FullscreenView class="section"  id="therapy" btnText="Programează-te" :title="$t('terapie_main_title')"  adress="therapy" :darken="true" :nr="2"/>
    <FullscreenView class="section"  id="surgery" btnText="Programează-te" :title="$t('surgery_main_title')" adress="surgery" :darken="true" :nr="3"/>
    <ImplantologyComponent class="section"   id="implantology" btnText="Programează-te" :title="$t('implantology_main_title')" adress="implantology" :nr="4" :darken="true"/>
    <FullscreenView class="section"  id="prosthetics" btnText="Programează-te" :title="$t('prosthetics_main_title')" adress="prosthetics" :darken="true" :nr="5"/>
    <FullscreenView class="section"  id="ortodonty" btnText="Programează-te" :title="$t('ortodonty_main_title')"  adress="ortodonty" :darken="true" :nr="6"/>
    <FullscreenView class="section"  id="pediatrics" btnText="Programează-te" :title="$t('pediatrics_main_title')" adress="pediatrics" :darken="true" :nr="7"/>
    <FooterComponent class="section"  />

</full-page>
</template>


<script>
import MenuComponent from '../common/MenuComponent.vue'
import StickyContacts from '../common/StickyContacts.vue'
import FooterComponent from '../components/FooterComponent.vue'
import SignUp from '@/common/SignUp.vue'
import FullscreenView from '@/common/FullscreenView.vue'
import ImplantologyComponent from '@/components/ImplantologyComponent.vue'
export default {
    name: "HomeView",
     mounted() {
        document.querySelector('.fp-watermark').style.display = 'none';
        if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        
        } else {
            // Set a flag so that we know not to reload the page twice.
            localStorage.setItem('reloaded', '1');
            location.reload();
            
        }
    },
    components: {
        MenuComponent,
        StickyContacts,
        FooterComponent,
        SignUp,
        FullscreenView,
        ImplantologyComponent
    },
    data() {
        return {
            options: {
                licenseKey: 'gplv3-license',
                fixedElements: '#menu,#signup,#sticky',
                navigation: true,
                navigationPosition:'left',
            },
        }
    },
     beforeUnmount() {
      console.log('aaa');
        localStorage.removeItem('reloaded');
    }
}
</script>

<style>

</style>

