import axios from "axios";

const baseUrl = process.env.VUE_APP_API_BASE_URL;

export const api = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});
