import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ServiceView from '../views/ServiceView.vue'
import EsteticView from '../views/EsteticView.vue'
import TherapyView from '../views/TherapyView.vue'
import SurgeryView from '../views/SurgeryView.vue'
import ImplantologyView from '../views/ImplantologyView.vue'
import ProstheticsView from '../views/ProstheticsView.vue'
import OrtodontyView from '../views/OrtodontyView.vue'
import PediatricsView from '../views/PediatricsView.vue'
import { Translation } from '@/plugins/dictionary/translations';
import i18n from '@/i18n';
const routes = [
  {
    path: '/',
    redirect: {name:'home'}
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/estetica',
    name: 'estetica',
    component: EsteticView
  },
  {
    path: '/therapy',
    name: 'therapy',
    component: TherapyView
  },
  {
    path: '/surgery',
    name: 'surgery',
    component: SurgeryView
  },
  {
    path: '/implantology',
    name: 'implantology',
    component: ImplantologyView
  },
  {
    path: '/prosthetics',
    name: 'prosthetics',
    component: ProstheticsView
  },
  {
    path: '/ortodonty',
    name: 'ortodonty',
    component: OrtodontyView
  },
  {
    path: '/pediatrics',
    name: 'pediatrics',
    component: PediatricsView
  },
  {
    path: '/services',
    name: 'services',
    component: ServiceView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },

]

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
  
})

loadPageTranslations();

async function loadPageTranslations() {
  const i18nMessages = await convertTranslations();
  for (const locale of Object.keys(Translation.locales)) {
    i18n.global.setLocaleMessage(locale, i18nMessages[locale]); 
  }

}
async function convertTranslations() {
  const i18nMessages = await Translation.getTranslations();
  return Translation.convertTranslations(i18nMessages);
}
