<template>
    <div class="content">
        <div v-if="showModal" class="modal fade" id="signUp" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              
                <button id="close" class="close zerowidth" data-bs-dismiss="modal" aria-label="Close" aria-hidden="true"> <img class="close" src="../assets/img/close.png" style="cursor:pointer">
                </button>
                <div class="modal-content">
                    
                <div class="modal-header">
                     
                    <img src="../assets/img/people.png">
                    <!--button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button-->
                </div>  
                <div class="modal-body">
                    <div class="form">
                        <div class="form-title">
                            <p>{{$t('main_contact_form_title')}}</p>
                        </div>
                        <form action="" id="myform" v-on:submit="submitForm" >
                            <input class="bold" type="text" :placeholder="$t('main_contact_placeholder_name')"  v-model="form.name" required>
                            <div style="color:#939393!important"><input type="tel" minlength="9" maxlength="9" pattern="[0]{1}[0-9]{2}[0-9]{3}[0-9]{3}" v-model="form.phone" :placeholder="$t('main_contact_phone')" color="black" class="phone-input bold" required></div>
                            <input class="bold" type="email" placeholder="E-mail.." v-model="form.email" required>
                            <div class="tacbox">
                                <input id="checkbox2" type="checkbox" />
                                <label @click="showTos=true" class="checkbox-label" for="checkbox2" required> {{$t('main_contact_tos_accept')}}</label>
                            </div>
                            <div class="button-div">
                                <input class="submit" type="submit" :value="$t('main_contact_form_submit')"/>
                            </div>
                        </form>
                    </div>
                    
                </div>
                
                
                </div>
            </div>
        </div>
        <Modal v-model:visible="isVisible">
            <div>Notificarea a fost trimisă cu succes</div>
        </Modal>
        <Dialog v-model:visible="showTos" @after-hide="this.showTos=false">
            <template #header>
                    <h3>{{$t('tos_title')}}</h3>
            </template>
           
            <template #footer>
                <div class="" v-html="$t('tos_desc')">
                        
                </div>
            </template>
        </Dialog>
    </div>
    
</template>

<script>
import { Modal } from 'usemodal-vue3';
import {Mail} from '@/plugins/backend/mail'


export default {
    name: "SignUp",
    components: {
        Modal,
    },
    methods: {
        submitForm(e) {
            e.preventDefault()
            document.getElementById('close').click();
            this.isVisible = true
            document.querySelector(".modal").classList.add("hidden");
            document.querySelector(".modal-backdrop").classList.add("hidden");
            Mail.sendMail(this.form)
        }
    },
    data() {
        return {
            title: 'Turism Dentar',
            desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
            isVisible: false,
            showModal: true,
            form: {
                name: '',
                email: '',
                phone: ''
            },
            showTos:false
        }
    }
}
</script>

<style scoped>
    .content {
        position: absolute;
        display: flex;
        justify-content: center;
        font-family: 'Montserrat';
    }
    .zerowidth {
        padding-left: 0; padding-right: 0;
         border-left-width: 0; border-right-width: 0;

    }
    .submit {
        min-width: 45vw;
        height: 5.5vh;
        min-height: 49px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        margin-top: 1.5vh;
        text-align: center;
        cursor: pointer;
        background-color: #FFE98B;
        color:#1C4437;
        font-family: 'Montserrat';
        font-weight: bold;
        font-size: 1.02vw;
    }
    .phone-input {
        background: url('../assets/img/moldova.png') no-repeat scroll 18px 7px;
        background-size:8%;
        padding-left: 70px;
        background-color: #DEDEDE;
    }
    .modal {
        backdrop-filter: blur(1.8px);
    }
    .modal-dialog {
       margin-top: 8vh;
       filter: blur(0.5);
       position: relative;
    }
   .modal-content {
        width: 370px;
        height: auto;
        margin: auto;
        
   }
   .hidden {
    display:none;
   }
   .modal-header {
        border-bottom: 0;
   }
   .modal-footer {
        border-top: 0;
   }
   .close {
        position: absolute;
        right: 0;
        top:-20px;
        cursor: pointer;
   }
   .submit {
        font-size:2.5vw;
    }
   .tacbox {
        display: grid;
        margin: 15px auto 15px auto;
        grid-template-columns: 1em auto;
        gap: 0.5em;
        justify-content: center;
    }
    .form-title p{
        text-align: center;
        font-size: 21px;
        font-weight: 700;
    }
    input, .vue-tel-input {
        background-color: #DEDEDE;
        color:#939393;
        font-size: 12px;
        border:0;
        border-radius: 4px;
        height: 49px;
        width:100%;
        padding-left: 20px;
        margin: 10px auto 10px auto;
    }
    input:focus {
        border :1px solid #1B4438;
    }
    .checkbox-label {
        font-family: 'Montserrat';
        font-size: 13px;
        font-weight: 600;
        text-align: center;
    }
    input[type="checkbox"] {
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        margin: auto;
        font: inherit;
        color: currentColor;
        width: 18px;
        height: 18px;
        border: 0.15em solid #1B4438;
        border-radius: 50%;
        display: grid;
        padding-left: 13px;

        place-content: center;
    }
    input[type="checkbox"]::before {
        content: "";
        width: 10px;
        height: 10px;
        margin: auto;
        border-radius: 50%;
        transform: scale(0) translateX(-68%);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #1B4438;
    }
    input[type="checkbox"]:checked::before {
        transform: scale(1) translateX(-68%);
    }
    .button-div {
        display: flex;
        justify-content: center;
    }
    @media screen and (min-width:900px) {
        .modal-dialog {
            margin-top: 8vh;
            position: relative;
        }
        .modal-content {
            width: auto;
            height: auto;
            margin: auto;       
        }
    }
    @media screen and (min-width:1200px) {
        .modal-content {
            width: 432px;
            margin-top: 40px;
        }
        .submit {
            min-width: 13vw;
            margin:auto;
            height: 6.3vh;
            min-height: 49px;
            font-size: 1.02vw;
        }
    }
</style>

