<template>
    <div class="content" :style="backgroundStyles(image)">
        
        <div class="main-div row">
            <div class="col-12 d-flex justify-content-center">   
                <p class="main-text-p">{{$t('main_services_title')}}</p>
            </div>
            <div class="col-12 d-flex justify-content-center">   
                <p class="desc-text-p" v-html="$t('main_services_desc')"></p>
            </div>
            <div class="col-12 d-flex justify-content-center"><router-link to="/services"><ButtonComponent color="yellow" :text="$t('main_services_button')" /></router-link></div>
        </div>
        
    </div>
</template>

<script>
import ButtonComponent from '../common/ButtonComponent.vue'
import { Image } from '@/plugins/backend/images'
import { useWindowSize } from 'vue-window-size'

export default {
    name: "ServiceComponent",
    components: {
        ButtonComponent
    },
    created() {
        if (this.windowWidth > 450) {
            Image.getImage('main_service_image').then(data => {
                this.image = this.$store+data;
            })
            }   
        else {
            Image.getImage('main_service_image_mobile').then(data => {
                this.image = this.$store+data;
            })
        }
    },
    setup() {
        const { width, height } = useWindowSize();
        return {
            windowWidth: width,
            windowHeight: height
        }
    },
    methods: {
        backgroundStyles(image) {
            return {
                'background-image': `url(${image})`,
            }
        }
    },
    data() {
        return {
            image:''
        }
    }
    
}
</script>

<style scoped>
    .content {
        background: url('../assets/img/services_mobile.png');
        background-position:center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100vw;
        box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.6);
        display: flex;
    }
    .main-div {
        margin: auto;
        position: relative;
    }
    .main-text-p {
        font-size: 7.45vw;
        font-family: 'Montserrat';
        color:white;
    }
    .desc-text-p {
        font-family: 'Montserrat';
        font-size: 0.85rem;
        color:white;
        text-align: center;
        padding: 0 4vw 0 4vw;
    }
    @media only screen and (min-width:600px) {
        .desc-text-p {
            font-size: 2.5vw;
        }
    }
     @media only screen and (min-width:920px) {
        .content {
        }
        .main-div {
            width: 66.4vw;
        }
        .main-text-p {
            font-size: 3.65vw;
        }
        .desc-text-p {
            font-size: 1.17vw;
        }
    }
</style>

