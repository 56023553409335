<template>
    <div class="content" :style="backgroundStyles(image)">
        <div class="mobile">
            <div class="container-contact-div">
                <ContactComponent />
            </div>
          
            <div class="lower-contact">
                 <div class="pointer jump">
                    <a href="https://www.google.com/maps/place/Strada+Alecu+Russo+61%2F6,+Chi%C8%99in%C4%83u+2044/data=!4m2!3m1!1s0x40c97c8a2d261923:0xb92854f01044e996?sa=X&ved=2ahUKEwjkpoeI-9L4AhU5wQIHHYwiDRkQ8gF6BAgSEAE"><img class="pointer-img jump" src="../assets/img/point.svg"></a>
                </div>
            </div>
        </div>
        <div class="desktop">
            <div class="container-fluid topbar row justify-content-between">
                <div class="pointer">
                    <a href="https://www.google.com/maps/place/Strada+Alecu+Russo+61%2F6,+Chi%C8%99in%C4%83u+2044/data=!4m2!3m1!1s0x40c97c8a2d261923:0xb92854f01044e996?sa=X&ved=2ahUKEwjkpoeI-9L4AhU5wQIHHYwiDRkQ8gF6BAgSEAE"><img class="pointer-img jump "  src="../assets/img/point.svg"></a>
                </div>
                <div class="col-4 d-flex">
                    <div class="form">
                        <div class="form-img-div">
                            <img class="form-img" src="../assets/img/dent.png"> 
                        </div>
                        <div class="form-title">
                            <p>{{$t('main_contact_form_title')}}</p>
                        </div>
                        <form action="" id="myform" v-on:submit="submitForm">
                            <input type="text" :placeholder="$t('main_contact_placeholder_name')"  v-model="form.name" required>
                            <div style="color:#939393!important"><input type="tel" minlength="9" maxlength="9" pattern="[0]{1}[0-9]{2}[0-9]{3}[0-9]{3}" v-model="form.phone" :placeholder="$t('main_contact_phone')" color="black" class="phone-input bold" required></div>
                            <input name="email" type="email" v-model="form.email" placeholder="E-mail.." required>
                            <div class="tacbox">
                                <input id="checkbox" type="checkbox" required />
                                <label @click="showTos = true" class="checkbox-label" for="checkbox">{{$t('main_contact_tos_accept')}}</label>
                            </div>
                            <input class="submit" type="submit" :value="$t('main_contact_form_submit')"/>
                           
                        </form>
                    </div>
                </div>
                
                <div class="col-4 contact-div">
                    <ContactComponent />
                </div>
            </div>
        </div>
                            <Dialog v-model:visible="showModal">
                                <template #header>
                                    <h3>Notificare</h3>
                                </template>

                               Notificarea a fost trimisă

                                <template #footer>
                                    <Button label="Ok" icon="pi pi-check" autofocus @click="showModal = false" />
                                </template>
                            </Dialog>  
        <Dialog v-model:visible="showTos" @after-hide="this.showTos=false">
            <template #header>
                    <h3>{{$t('tos_title')}}</h3>
            </template>
           
            <template #footer>
                <div class="" v-html="$t('tos_desc')">
                        
                </div>
            </template>
        </Dialog>   
    </div>

            
</template>

<script>

import ContactComponent from '@/components/ContactComponent.vue'
import {Mail} from '@/plugins/backend/mail'
import Button from 'primevue/button';
import { Image } from '@/plugins/backend/images'


export default {
    name: "ContactsMobile",
    components: {
        ContactComponent, 
        Button,
        
    },
    data() {
        return {
            showModal:false,
            title: 'Contacte',
            desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
            form: {
                name: '',
                email: '',
                phone: ''
            },
            showTos:false,
            image:'',
            
        }
    },
    created() {
        Image.getImage('main_map_image').then(data => {
            this.image = this.$store+data;
        })
    },
    methods: {
        onInput(value) {
            if (value === "") {
                this.validValue = value;
            } else if (value.length >= 10) {
                this.$forceUpdate();
            } else if (!/^[1-9][0-9]*$/.test(value)) {
                this.$forceUpdate();
            } else {
                this.validValue = value;
            }
        },
        submitForm(e) {
            e.preventDefault() 
            Mail.sendMail(this.form)
            .then(() => {
                this.showModal = true
            })
        },
        backgroundStyles(image) {
            return {
                'background-image': `url(${image})`,
            }
        }
    },
}
</script>

<style scoped>
    .content {
        box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.8);
        background-position:center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100vw;
        display: flex;
        overflow: hidden;
    }
    .container-contact-div {
        height: 55%;
        margin-top: 0;
    }
    .mobile {
        margin-top: 0;
        height: 100%;
    }
    .desktop {
        display:none;
    }
    .lower-contact {
        width: 100vw;
        height: 40%;
        position: relative;
        background-position:center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow:hidden;
    }
    .phone-input {
        background: url('../assets/img/moldova.png') no-repeat scroll 18px 7px;
        background-size:8%;
        padding-left: 70px;
    }
    .main-div {
        margin: auto;
        position: relative;
    }
    .main-text-p {
        font-size: 1.94rem;
        font-weight: 600;
        font-family: 'Montserrat';
        color:white;
    }
    .submit {
        min-width: 45vw;
        height: 5.5vh;
        min-height: 49px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        margin-top: 1.5vh;
        text-align: center;
        cursor: pointer;
        background-color: #FFE98B;
        color:#1C4437;
        font-family: 'Montserrat';
        font-weight: bold;
        font-size: 1.02vw;
    }
    .desc-text-p {
        font-family: 'Montserrat';
        font-size: 0.85rem;
        color:white;
        text-align: center;
        padding: 0 4vw 0 4vw;
    }
    .pointer {
        position: absolute;
        transform: translateX(-50%);
        left:56%;
        top: 30%;
        margin: auto;
    }
    .pointer-img {
        margin: auto;
              justify-content: center;
       display:flex;
    }
    @keyframes jump {
        0% {
            transform: translate3d(-50%, -30%, 0) scale3d(1, 1, 1);
        }
        40% {
            transform: translate3d(-50%, -10%, 0) scale3d(0.95, 1.05, 1);
        }
        100% {
            transform: translate3d(-50%, 0%, 0) scale3d(1.05, 0.95, 1);
        }
    }
    .jump {

        animation: jump 0.5s linear alternate infinite;

    }
     @media only screen and  (min-width:600px) {

    }
    @media only screen and  (min-width:920px) {
        .mobile {
            display: none;
        }
        .desktop {
            display:flex;
            width:  100%;
            height: 100vh;
            margin: auto;
        }
        .contact-div {
            margin: 0 10% auto auto;
        }
        .submit {
            min-width: 13vw;
            margin:auto;
            height: 6.3vh;
            min-height: 49px;
            font-size:1.3vw;
        }
        .content {
        }
        .main-div {
            width: 66.4vw;
        }
        .main-text-p {
            font-size: 3.65vw;
        }
        .desc-text-p {
            font-size: 1.17vw;
        }
         .close {
            margin-bottom: 21px;
        }
        .tacbox {
            display: grid;
            margin: 12px auto 12px auto;
            grid-template-columns: 1em auto;
            gap: 0.5em;
            justify-content: center;
            font-size: 0.95vw;
            color:#FFE898;
        }
        .form {
            margin: -10% auto auto auto;
            width: 90%;
        }
        .form-img-div {
            display:flex;
        }
        .pointer {
            left: 51.5%;
        }
        .form-img {
            margin: auto;
            width:20%;
            height: auto;
            padding-bottom: 3vh;
        }
        .form-title p{
            text-align: center;
            font-size: 1.61vw;
            font-weight: 500;
            color:#FFE898;
        }
        .topbar {
            padding: 2vh 5.5vw 2vh 5.5vw;
            position:relative;
            margin: auto;
        }
        input, .vue-tel-input {
            background-color: transparent;
            color:#FFE898;
            font-size: 12px;
            font-family: 'Montserrat';
            font-weight: bold;
            border: 1px solid #FFE898;
            border-radius: 4px;
            height: 49px;
            width:100%;
            padding-left: 20px;
            margin: 10px auto 10px auto;
        }
        .checkbox-label {
            font-size: 1vw;
            font-weight: 600;
            margin-left: 0.5rem;
        }
        input[type="checkbox"] {
            position: relative;
            display: block;
            cursor: pointer;
            -webkit-appearance: none;
            appearance: none;
            background-color: transparent;
            margin: auto;   
            font: inherit;
            color: currentColor;
            width: 20px;
            height: 20px;
            border: 0.15em solid #FFE898;
            border-radius: 50%;
            padding-left: 13px;
            display: grid;
            place-content: center;
            pointer-events: all;
        }
        input[type="checkbox"]::before {
            content: "";
            position: relative;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            transform: scale(0) translateX(-50%);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em #FFE898;
        }
        input[type="checkbox"]:checked::before {

            transform: scale(1) translateX(-50%);;
        }
        .button {
            width:100%;
        }
        
    }
    
    @media only screen and  (min-width:1200px) {
        .contact-div {
            margin: 0 0 auto auto;
        }
        .submit {
            font-size: 1.02vw;
        }
        
    }

    ::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

