import { createI18n } from 'vue-i18n'
import { Translation } from './plugins/dictionary/translations';




export default createI18n({
  legacy: false,
  isLoading:true,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {}
})

export const loadLanguage = async function() {
   await Translation.getTranslations()

}