<template>

<full-page :options="options">
        <MenuComponent data-anchor="top" id="menu"  />
        <SignUp id="signup"  />
        <StickyContacts id="sticky" /> 
        <MainSection class="section" />
        <ServiceComponent class="section" />
        <DentalTourism class="section"/>
        <ContactsMobile class="section"/>
        <FooterComponent class="section" />

</full-page>
       

</template>

<script>
import MenuComponent from '../common/MenuComponent.vue'
import StickyContacts from '../common/StickyContacts.vue'
import MainSection from '@/components/MainSection.vue'
import ServiceComponent from '@/components/ServiceComponent.vue'
import DentalTourism from '@/components/DentalTourism.vue'
import ContactsMobile from '../components/ContactsMobile.vue'
import FooterComponent from '../components/FooterComponent.vue'
import SignUp from '@/common/SignUp.vue'

export default {
    name: "HomeView",
    mounted() {
        document.querySelector('.fp-watermark').style.display = 'none';
        if (localStorage.getItem('reloaded')) {
                //*adawda
        } else {
            localStorage.setItem('reloaded', '1');
            location.reload();
            
        }
    },
    components: {
        MenuComponent,
        StickyContacts,
        MainSection,
        ServiceComponent,
        DentalTourism,
        ContactsMobile,
        FooterComponent,
        SignUp
    },
    methods: {
        
    },
    data() {
        return {
            options: {
                licenseKey: 'gplv3-license',
                fixedElements: '#menu,#signup,#sticky,#wtf',
                navigation: true,
                navigationPosition:'left',
                animateAnchors: true
            },
        }
    },
     beforeUnmount() {
      console.log('aaa');
        localStorage.removeItem('reloaded');
    }
}

</script>

<style>

    #fp-nav ul li a span,
    .fp-slidesNav ul li a span {
        background: #FFF3C1!important;
    }
</style>

