<template>
<link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'>
 <link
  rel="stylesheet"
  href="https://unpkg.com/vue-fullpage.js/dist/style.css"
/>
  <router-view/>  
  <div class="vl-parent">
        <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"
                 :opacity="1"
                 />

        <label><input type="checkbox" v-model="fullPage">Full page?</label>
        <button @click.prevent="doAjax">fetch Data</button>
    </div>
</template>

<script>
//import HomeView from './views/HomeView.vue'
import { useI18n } from 'vue-i18n'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import { loadLanguage } from './i18n';
export default {
    name: "App",
    components: { 
      Loading
    },
    setup() {
        const { t } = useI18n({
        inheritLocale: true,
        useScope: 'local'
      })

      return { t }
    },
    data() {
      return {
        isLoading: true,
        fullPage: true,
        currentLanguage:localStorage.language,  
        locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
      }
    },
    mounted() {
      this.$i18n.locale = this.locales[this.currentLanguage]; 
      loadLanguage()
      .then(() => {
          this.isLoading=false
      })
    }
}
</script>

<style>

body::-webkit-scrollbar {
    width: 0.2em;
  }
   
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    padding: 0;
    padding-left: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: middle;
  }
  ol, ul {
    list-style: none;
  }
  
  html {
    scroll-behavior: smooth;
    font-family: 'Montserrat'!important;
    line-height: 1.5;
  }
  body {
    padding-right: 0 !important
  }
 .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
     padding-right: 0px!important; 
     padding-left: 0px!important; 
}
.bold {
  font-weight: 700;
}
a {
  text-decoration: none;
}
html, body {
height: 100%;
}
.vl-parent{
  height: 100%;
}
</style>
