<template>

<full-page :options="options">
    
        <MenuComponent id="menu" :whiter="true"  />
        <SignUp id="signup"  />

        <TextFull class="section" :title='title_full' :desc='desc_full' :imgLink='img_full' translation="terapie" />
        <TextRight class="section" :title='title_right' :desc='desc_right' :imgLink='img_right' translation="terapie" />
        <TextNone class="section" :imgLink='img_fullscreen' :isVideo="true" translation="terapie"  />
        <TextLeft class="section" :title='title_left' :desc='desc_left' :imgLink='img_left' translation="terapie" />

        <StickyContacts id="sticky" /> 
        <FooterComponent class="section" />
    
</full-page>
       
</template>

<script>
import MenuComponent from '../common/MenuComponent.vue'
import StickyContacts from '../common/StickyContacts.vue'

import TextFull from '@/common/Sections/TextFull.vue'
import TextRight from '@/common/Sections/TextRight.vue'
import TextNone from '@/common/Sections/TextNone.vue'
import TextLeft from '@/common/Sections/TextLeft.vue'

import FooterComponent from '../components/FooterComponent.vue'
import SignUp from '@/common/SignUp.vue'

export default {
    name: "TherapyView",
    mounted() {
        document.querySelector('.fp-watermark').style.display = 'none';
        window.fullpage_api.moveTo(1)
    },
    data() {
        return {
            options: {
                licenseKey: 'gplv3-license',
                fixedElements: '#menu,#signup,#sticky',
                navigation: true,
                navigationPosition:'left',
            },
            title_full: 'Terapie',
            desc_full:'La PrimDent, ne asigurăm că fiecare pacient beneficiază de un tratament calitativ și personalizat, realizat cu ajutorul celor mai noi tehnologii de către medicii noștri. Pentru noi fiecare detaliu contează. De aceea, am ales un design modern pentru cabinetele noastre dotate cu aparataj performant – totul pentru a oferi pacienților noștri soluții sigure și eficiente în timpul tratamentului, realizate într-o atmosferă plăcută și relaxantă.',
            img_full:'/img/Terapie/main.png',
            title_right:'De ce alegem obturație - restaurare de la PrimDent?',
            desc_right:'Noi nu doar plombăm dintele, noi îi redăm aspectul natural, o funcție și morfologie corectă! În tratamentul cariilor dentare, scopul principal este acela de a realiza o lucrare minim-invazivă, conservativă și estetică, ce pune accent pe menținerea integrității dintelui natural. Astfel, prin tratarea cariilor realizată în clinica noastră se îndepărtează strict aria cavității cariate și se obturează (plombează) cu material compoziț, care păstrează aspectul natural al dinților prin nuanță, transluciditate și textură, redându-le acestora funcționalitatea.',
            img_right:'/img/Terapie/left.png',
            title_left:'Noi salvăm chiar și dinții compromiși!',
            desc_left:'Înainte de a extrage un dinte, vină la noi și noi putem găsi soluția de a-l salva! Nimic nu poate  fi mai bun decât dintele natural! Acesta este afirmația  noastră și de aceea noi insistăm la tratamentul dinților și la reabilitarea lor chiar și în cele mai complicate cazuri. Traramentul endodontic (cunoscut și sub denumirea de tratament de canal) este una dintre cele mai frecvente proceduri stomatologice ce constă în îndepărtarea conținutului canalelor radiculare, curățirea, dezinfectarea și sigilarea lor cu scopul de a salva dintele natural și de a restabili funcționalitatea acestuia.  Chiar dacă aveți un caz mai complicat, noi găsim soluția potrivită! În clinica PrimDent avem medici cu experiență în domeniu ,mereu la curent cu ultimele noutăți și metode de tratament. Rapid și fără  durere, în scurt timp afecțiunea cu care vă prezentați va fi tratată.',
            img_left:'/img/Terapie/right.png',
            img_fullscreen:'/img/Terapie/video.mp4',
        }
    },
    components: {
        MenuComponent,
        StickyContacts,

        TextFull,
        TextRight,
        TextNone,
        TextLeft,
        
        FooterComponent,
        SignUp
    },
}

</script>

<style>
@import '@/assets/css/global.css';
</style>

