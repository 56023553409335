<template>

    <div class="section-container d-flex">
        <video v-if="this.video!=''"  preload="metadata" muted autoplay loop style="pointer-events: none;" class="video-overlay image-section video-section-about">
                <source :src="video" type="video/webm">
                <source :src="video" type="video/mp4">
              </video>
        <div class="text-section text-section-right">
            <div class="text-container-right">
                <div class="text-medium text-center">
                    {{$t(translation + '_right_title')}}
                </div>
                <div class="text-small line-28 margin-top-25" v-html="$t(translation + '_right_desc')">
                </div>
                <div class="button-container margin-top-25">
                    <ButtonComponent color="yellow" :text="$t(translation + '_right_button')" data-bs-toggle="modal" data-bs-target="#signUp"  />
                </div>
                
            </div>
        </div>
    </div>

</template>

<script>
import ButtonComponent from '@/common/ButtonComponent.vue'
import { Image } from '@/plugins/backend/images'
import { useWindowSize } from 'vue-window-size';

export default {
name: "TextRight",
components: {
    ButtonComponent
},
created() {
    if (this.windowWidth > 450) {
        Image.getImage(`${this.translation}_image2`).then(data => {
            this.image = this.$store+data;
        })
    }
    else {
        Image.getImage(`${this.translation}_image2_mobile`).then(data => {
            this.image = this.$store+data;
        })
    }
    
},
setup() {
    const { width, height } = useWindowSize();
    return {
        windowWidth: width,
        windowHeight: height
    }
},
methods: {
},
props: {
    imgLink:String,
    title:String,
    desc:String,
    translation: String
},
data() {
    return {
       video:'',
    }
},
mounted() {
    Image.getImage(`about_image2`).then(data => {
                this.video = this.$store+data;
            })
}

}
</script>

<style scoped>
@import '@/assets/css/global.css';
</style>

