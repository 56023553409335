<template>
    <div v-if="isVideo">
        <div class="video-container">
              <video v-if="this.video!=''" preload="metadata" muted autoplay loop style="pointer-events: none;" id="sectionVideo">
                <source :src="video" type="video/webm">
                <source :src="video" type="video/mp4">
            </video>
        </div>
    </div>
    <div v-else class="fh overlay">
        <div class="fh" :style="backgroundStyles(image)"> 
        </div>
    </div>
</template>

<script>
import { Image } from '@/plugins/backend/images'
import { useWindowSize } from 'vue-window-size';

export default {
    name: "TextNone",
    props: {
       imgLink:String,
       isVideo: {
            default:false,
            type:Boolean
        },
        translation:String
    },
    data() {
        return {
            video:'',
            image:'',
            mobile_image:'',
        }
    },
    setup() {
        const { width, height } = useWindowSize();
        return {
            windowWidth: width,
            windowHeight: height
        }
    },
    mounted() {
        if (this.isVideo == true){
            Image.getImage(`${this.translation}_video3`).then(data => {
                this.video = this.$store+data;
            })
        }    
        else {
            if (this.windowWidth > 450) {
                Image.getImage(`${this.translation}_image3`).then(data => {
                    this.image = this.$store+data;
                })
            }
            else {
                Image.getImage(`${this.translation}_image3_mobile`).then(data => {
                    this.image = this.$store+data;
                })
            }
            
        }
       
    },
    methods: {
        backgroundStyles(image) {
                return {
                    'background-image': `url(${image})`,
                }
        }
    },

}
</script>

<style>

</style>

