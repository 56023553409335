<template>
    <div class="content" v-bind:class="{dark:darken}">
        <div class="video-container">
              <video v-if="video!=''" autoplay muted loop playsinline id="myVideo">
                <source :src="video" type="video/webm">
                <source :src="video" type="video/mp4">
            </video>
        </div>
      
        <div class="main-div row">
                <div class="col-12 d-flex justify-content-center">   
                    <p class="main-text-p">{{$t('implantology_main_title')}}</p>
                </div>
                
                <div class="col-12 d-flex justify-content-center"><router-link to="/implantology"><ButtonComponent color="white" :text="$t('buttons_find_out_more')" data-toggle="modal" data-target="#signUp"  /></router-link></div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/common/ButtonComponent.vue'
import { Image } from '@/plugins/backend/images';
export default {
    name: "ImplantologyComponent",
    props: {
       title: String,
       imgLink:String,
       btnText:String,
       darken: {
            type: Boolean,
            default:false
       },
       isVideo: {
            type:Boolean,
            default:false
       }
    },
    components: {
        ButtonComponent
    },
    mounted() {
        Image.getImage('services_video4').then(data => {
                this.video = this.$store+data;
            })
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            video:''
        }
    },
}
</script>

<style scoped>
    .content {
        position: relative;
        background-position:center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        width: 100vw;
        display: flex;
      
    }
    .video-container {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%; 
        overflow: hidden;
    }
     #myVideo {
         /* Make video to at least 100% wide and tall */
        min-width: 100%; 
        min-height: 100%; 

        /* Setting width & height to auto prevents the browser from stretching or squishing the video */
        width: auto;
        height: auto;

        /* Center the video */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
        .main-div {
        margin: auto;
        position: relative;
         width: 66.4vw;
    }
    .main-text-p {
              font-size: 7.45vw;
        font-family: 'Montserrat';
        color:white;
    }
    .desc-text-p {
        font-family: 'Montserrat';
        font-size: 0.81rem;
        color:white;
        text-align: center;
        padding: 0 5vw 0 5vw;
    }
    .dark {
        box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.25);
    }

    @media screen and (min-width:920px){
        .main-text-p {
            font-size: 3.65vw;
        }
    }
</style>

