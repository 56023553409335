<template>

<full-page :options="options">

        <MenuComponent id="menu"  />
        <SignUp id="signup"  />

        <TextFull class="section"  :title='title_full' :desc='desc_full' :imgLink='img_full' translation="about"/>
        <TextRightVideo class="section" :title='title_right' :desc='desc_right' :imgLink='img_right' translation="about" />
        <TextLeft class="section" :title='title_left' :desc='desc_left' :imgLink='img_left' translation="about" />
        <AboutThird class="section" />

        <StickyContacts id="sticky" /> 
        <FooterComponent class="section" />

</full-page>
       
</template>

<script>
import MenuComponent from '../common/MenuComponent.vue'
import StickyContacts from '../common/StickyContacts.vue'

import TextFull from '@/common/Sections/TextFull.vue'
import TextRightVideo from '@/common/Sections/TextRightVideo.vue'
import TextLeft from '@/common/Sections/TextLeft.vue'

import FooterComponent from '../components/FooterComponent.vue'
import SignUp from '@/common/SignUp.vue'
import AboutThird from '@/components/AboutThird.vue'

export default {
    name: "SurgeryView",
    mounted() {
        document.querySelector('.fp-watermark').style.display = 'none';
    },
    data() {
        return {
            options: {
                licenseKey: 'gplv3-license',
                fixedElements: '#menu,#signup,#sticky',
                navigation: true,
                navigationPosition:'left',
            },
            title_full: 'Chirurgie',
            desc_full:'Dacă ai nevoie să extragi o măsea de minte sau a unui dinte irecuperabil, sau ai nevoie de plastia gingii, noi te așteptăm cu drag! Echipa noastră de medici, aici la PrimDent, are o experiență considerabilă în ceea ce privește chirurgia dentară și te va ajuta ușor și simplu să scapi de problema ce îți dă bătăi de cap.',
            img_full:'/img/about/main.png',
            title_right:'Extracția dintelui de minte este mai simplă la PrimDent!',
            desc_right:'Datorită tehnicilor moderne și a anestezicelor de ultimă generație folosite în chirurgia dentară, acum pot fi efectuate proceduri chirurgicale nedureroase. Ultima generație de echipamente și instrumentar chirurgical ne oferă posibilitatea realizării unor operații mult mai rapide cu recuperare postoperatorie integrală în scurt timp. Chiar și extracția dintelui de minte, care a erupt sau nu a reușit definitiv,deja este mult mai simplă. Pentru noi contează siguranța dumneavoastră și de aceea vă oferim indicațiile necesare  înainte și după intervenție individual pentru fiecare caz.',
            img_right:'/img/about/left.png',
            title_left:'Când ai nevoie de plastia gingiei?',
            desc_left:'Gingivoplastia sau plastia gingivală constituie intervenţia chirurgicală de remodelare a gingiei sănătoase care înconjoară dintele.  Care sunt indicaţiile gingivoplastiei? 1. Zâmbetul gingival: se caracterizează prin expunerea unei suprafeţe mari din gingie atunci când pacientul zâmbeşte.  2. Gingivita şi parodontita: sunt situaţii când îmbolnăvirea gingiei poate conduce la modificarea definitivă a aspectului acesteia, chiar şi după remiterea bolii, prin hiperplazie şi reorientarea marginii gingivale.  3. Alungirea coroanei clinice a dintelui pentru a îmbunătăţi retenţia unei piese protetice. 4. Caria radiculară sau orice alt proces patologic care evoluează subgingival necesită îndepărtarea gingiei pentru a asigura accesul şi vizibilitatea necesare tratamentului, urmată de remodelarea conturului gingival, la sfârşitul intervenţiei.',
            img_left:'/img/about/right.png',
            img_fullscreen:'/img/about/video.png',
        }
    },
    components: {
        MenuComponent,
        StickyContacts,
        TextFull,
        TextRightVideo,
        TextLeft,
        FooterComponent,
        SignUp,
        AboutThird
    },
}

</script>

<style>
@import '@/assets/css/global.css';
</style>

