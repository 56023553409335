<template>
    <div class="mobile" :class="{white_mode:whiter}">
         <div class="container-fluid topbar-mobile">
            <div class="row justify-content-between topbar-row">
                <div class="col-2 logo-col">
                    <router-link to="/#/home"><img class="logo-img" src="../assets/img/logo.svg"></router-link>
                </div>
                <div class="col-4 menu-col">
                    <p class="menu-text">
                        Meniu
                    </p>
                    <img @click="changeState" class="menu-icon" src="../assets/img/menu.svg">
                </div>
            </div>
        </div>
        <div class="mobile_menu" ref="menu" v-bind:class="{menu_open: isOpen}">
            <ul class="mobile_menu_list">
                            <router-link class="menu-anchor" to="/estetica"><li>{{$t('menu_menu1')}}</li></router-link>
                            <router-link class="menu-anchor" to="/therapy"><li>{{$t('menu_menu2')}}</li></router-link >
                            <router-link class="menu-anchor" to="/surgery"><li>{{$t('menu_menu3')}}</li></router-link>
                            <router-link class="menu-anchor" to="/implantology"><li>{{$t('menu_menu4')}}</li></router-link>
                            <router-link class="menu-anchor" to="/prosthetics"><li>{{$t('menu_menu5')}}</li></router-link>
                            <router-link class="menu-anchor" to="/ortodonty"><li>{{$t('menu_menu6')}}</li></router-link>
                            <router-link class="menu-anchor" to="/pediatrics"><li>{{$t('menu_menu7')}}</li></router-link>
                            <li class="language-switch" @click="showByIndex=true"><p>{{languages[currentLanguage]}}</p>
                             <Transition name="fade">
                                <div class="other-lang" :class="{white_lang:whiter}" v-show="showByIndex" @mouseleave="showByIndex=false">
                                        <div class="row limbi justify-content-center">
                                            <div v-for="(language,index) in other_languages" class="col-6 col-lang" :key="language" @click="changeLanguage(index)">
                                                <p class="language">
                                                    {{ other_languages[index] }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </li>
                           
                            <a class="menu-anchor" href="tel:+373 79 19 19 41"><li>{{$t('phone_number')}}</li></a>
                            <li data-bs-toggle="modal" data-bs-target="#signUp">{{$t('menu_menu_button')}}</li>
            </ul>
        </div>
    </div>
   <div class="pc">
        <div class="container-fluid topbar" :class="{white_mode:whiter}">
           <div class="row ">
                <div v-if="!whiter" class="col-2 logo-col">
                    <router-link to="/" class="center"><img class="logo-img" src="../assets/img/logo.svg"></router-link>
                </div>
                <div v-else class="col-2 logo-col">
                    <router-link to="/" class="center"><img class="logo-img" src="../assets/img/logo_white.svg"></router-link>
                </div>
                <div class="col-6 menu-col d-flex">
                    <ul class="menu_list">
                        <li @mouseover="show1 = true" @mouseleave="show1=false"><router-link  :class="{white_link:whiter}" class="menu-anchor" to="/estetica">{{$t('menu_menu1')}}</router-link >
                            <Transition name="fade-point">
                                <div v-if="show1" class="point" :class="{white_point:whiter}" ></div>
                            </Transition>
                        </li>
                        
                        <li @mouseover="show2 = true" @mouseleave="show2=false"><router-link :class="{white_link:whiter}" class="menu-anchor" to="/therapy">{{$t('menu_menu2')}}</router-link>
                            <Transition name="fade-point">
                                <div v-if="show2" class="point" :class="{white_point:whiter}" ></div>
                            </Transition>
                        </li>
                        <li @mouseover="show3 = true" @mouseleave="show3=false"><router-link :class="{white_link:whiter}" class="menu-anchor" to="/surgery">{{$t('menu_menu3')}}</router-link>
                            <Transition name="fade-point">
                                <div v-if="show3" class="point" :class="{white_point:whiter}" ></div>
                            </Transition>
                        </li>
                        <li @mouseover="show4 = true" @mouseleave="show4=false"><router-link :class="{white_link:whiter}" class="menu-anchor" to="/implantology">{{$t('menu_menu4')}}</router-link>
                        <Transition name="fade-point">
                                <div v-if="show4" class="point" :class="{white_point:whiter}" ></div>
                            </Transition></li>
                        <li @mouseover="show5 = true" @mouseleave="show5=false"><router-link :class="{white_link:whiter}" class="menu-anchor" to="/prosthetics">{{$t('menu_menu5')}}</router-link>
                        <Transition name="fade-point">
                                <div v-if="show5" class="point" :class="{white_point:whiter}" > </div>
                            </Transition></li>
                        <li @mouseover="show6 = true" @mouseleave="show6=false"><router-link :class="{white_link:whiter}" class="menu-anchor" to="/ortodonty">{{$t('menu_menu6')}}</router-link>
                        <Transition name="fade-point">
                                <div v-if="show6" class="point" :class="{white_point:whiter}" ></div>
                            </Transition></li>
                        <li @mouseover="show7 = true" @mouseleave="show7=false"><router-link :class="{white_link:whiter}" class="menu-anchor" to="/pediatrics">{{$t('menu_menu7')}}</router-link>
                        <Transition name="fade-point">
                                <div v-if="show7" class="point" :class="{white_point:whiter}" ></div>
                            </Transition></li>
                      
                    </ul>
                </div>
                 <div class="col-2 contact-col d-flex"  >
                    <div class="lang-row" >
                        
                        <div class="circle" @mouseover="showByIndex=true" :class="{white_2:whiter}" >
                        <p>{{languages[currentLanguage]}}</p>
                        <Transition name="fade">   
                            
                                
                                <div class="other-lang" :class="{white_lang:whiter}" v-show="showByIndex"  >
                                    <div class="row limbi justify-content-center" @mouseleave="showByIndex=false">
                                        <div v-for="(language,index) in other_languages" class="col-6 col-lang" :key="language" @click="changeLanguage(index)">
                                            <p class="language">
                                                {{ other_languages[index] }}
                                            </p>
                                        </div>
                                            <!--div class="col-6 col-lang">
                                                <p class="language">
                                                    RU
                                                </p>
                                            </div>
                                        <div class="col-6 col-lang">
                                            <p class="language">
                                                EN
                                            </p>
                                        </div-->
                                    </div>
                                </div>
                                
                        </Transition>
                        </div>
                        <div class="phone-nr" >
                            <a :class="{white_link:whiter}"  href="tel:+373 79 19 19 41">{{$t('phone_number')}}</a>
                        </div>
                    </div>
                </div>
                <div class="col-2 button-col" v-if="!whiter">
                    <ButtonComponent color="yellow" :text="$t('menu_menu_button')" data-bs-toggle="modal" data-bs-target="#signUp"  />
                </div>
                <div class="col-2 button-col" v-else>
                    <ButtonComponent color="white" :text="$t('menu_menu_button')" data-bs-toggle="modal" data-bs-target="#signUp" />
                </div>
            </div>
        </div>
   </div>
   
</template>

<script>
import ButtonComponent from './ButtonComponent.vue';


export default {
    name: "MenuComponent",
    props: {
        whiter: {
            type: Boolean,
            default:false
       }
    },
    data() {
        return {
            isOpen:false,
            translateSize:60,
            phone: "+373 79 19 19 41",
            showByIndex:false,
            show:false ,
            language:false,
            show1:false,
            show2:false,
            show3:false,
            show4:false,
            show5:false,
            show6:false,
            show7:false,
            languages:['RO','RU','EN'],
            currentLanguage:localStorage.language,
            other_languages:[],
            locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
        }
    },
    mounted() {
        this.updateMenu();
    },
    updated() {

        this.updateMenu();
    },
    components: {
        ButtonComponent
    },
    methods: {
        changeState() {
            this.isOpen=!this.isOpen;
        },
        openTab() {
            this.language=true;
        },
        changeLanguage(index) {
            let trueIndex;
            for (let i = 0; i<this.languages.length; i++) {
                if (this.other_languages[index]==this.languages[i]) {
                    trueIndex=i;
                    break
                }
            }
            this.$language = trueIndex;
            this.currentLanguage = trueIndex;
            localStorage.language=trueIndex;
            this.showByIndex=false
            this.$i18n.locale = this.languages[trueIndex];
            this.updateMenu();
            this.$router.go()
        },
        updateMenu() {
            this.other_languages=[];

            for (let index = 0; index < this.languages.length; index++) {
                if (index != this.currentLanguage) {
                    this.other_languages.push(this.languages[index])
                } 
            }
        }
    }
}
</script>

<style scoped>
    .mobile {
        position: relative;
        z-index: 10;
        
    }
    .pc {
        display: none;
    
    }
    .topbar-mobile {
        position: absolute;
        background-color: rgba(28,68,55,0.9 );
        height: auto;
        padding-left: 5vw;
        padding-right: 5vw;
        padding-top: 2.8vh;
        padding-bottom: 2.8vh;
    }
    .topbar-row {
        color: #FFE898;
        margin: auto;
    }
    .menu-col {
        display: flex;
    }
    .logo-img {
        height: 3.46vh;
    }
    .menu-text {
        margin: auto 0 auto auto;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 0.82rem;
    }
    .menu-icon {
        position: relative;
        z-index: 2;
        width: auto;
        margin: auto 0 auto 15px;   
    }
	.mobile_menu {
		position: absolute;
		background-color: rgba(28,68,55,1);
		z-index: 1;
		opacity: 1;
		width: 60vw;
		height: 100vh;
		transform: translateX(60vw);
        top: 0;
		right: 0;
		bottom: 0;
		transition: 0.7s ease-in-out;
	}
	.mobile_menu_list {
        position: relative;
		width: 62.5%;
        margin: auto;
        padding-top: 10vh;
        color:#FFE98B;
        font-family: 'Montserrat';
        font-size: 0.875rem;
        font-weight: 500;
	}
    .mobile_menu_list li {
        position: relative;
        margin-top: 2.5vh;
        padding-bottom: 0.5vh;
        border-bottom: 1px solid #FFE898;
    } 
    .menu-anchor {
        color:#FFE898;
        text-decoration: none;
        position: relative;
        width:100%;
    }
    .languages {
        display: none;
    }
    .language {
        cursor: pointer;
        margin: auto;
    }
    .col-lang {
        display: flex;
        justify-content: center;
    }
    .other-lang {
        position:absolute;
        z-index: 1;
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 30vw;
        height: 5vh;
        border-radius: 4px;
        margin-top: 5px;
        border: 1px solid #FFE98B;
        background-color: rgba(28,68,55,1);;
        transition:0.2s ease-in-out;
    }
    .limbi {
        width:80%;
    }
    .show {
         display:block;
    }
    .menu_open {
        transform: translateX(0vw);
    }
    .menu_close {
        transform: translateX(60vw);
    }
    .fade-point-enter-active,
    .fade-point-leave-active {
        transition: all 0.2s ease-out;
     }
    .fade-enter-from,
    .fade-leave-to{
        opacity: 0; 
    } 

    @media screen and (min-width:600px) {
        .menu-text {
            font-size: 1.5rem;
        }
        .menu-icon {
            position: relative;
            z-index: 2;
            height: 80%;
            margin: auto 0 auto 15px;   
        }
        .mobile_menu_list {
            width: 70%;
            font-size: 1.6rem;
        }
        .mobile_menu_list li {
            margin-block: 3.5vh;
        }
    }
    
   

    @media screen and (min-width:1000px) {
        .mobile {
            display:none;
        }
        .pc {
            position: relative;
            display:block;
            z-index: 10;
        }

        .topbar {
            position: absolute;
            min-height: 80px;
           
            background-color: rgba(28,68,55,0.9);
            color: #FFE898;
            padding-left: 5.6vw;
            padding-right: 5.6vw;
            padding-top: 2vh;
            padding-bottom: 2vh;
        }
        .menu-anchor {
            padding-left:5px;
            color:#FFE898;
            text-decoration: none;
            position: relative;
        }
        .logo-col {
            position: relative;
            display:flex;
            flex: 0 0 10.4vw;
            max-width: 10.4vw;
        }
        
        .center {
            display:flex;
            justify-content: center;
        }
        .logo-img {
            width: 10.4vw;
            height: auto;
            margin:auto;
        }
        .menu-col {
            flex: 0 0 42vw;
            max-width: 42vw;
            margin-left: 1.1vw;
            margin-right: 6.15vw;
        }
        .contact-col {
           
        }
        .menu_list {

            display: flex;
            width: 100%;
            justify-content: space-between;
            margin: auto;

        }
        .menu_list li {
            position: relative;
            cursor: pointer;
            font-size: 1.02vw;
            font-family: 'Montserrat';
            font-weight: 500;
        }
         .other-lang {
            width: 6.5vw;
            margin-top: 65px;
        }
        .point {
            position: absolute;
            display:block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color:#FFE898;
            transform: translateY(5px);
            left: 50%;
            opacity: 1;
            transition: 0.15s ease-in-out;
        }
        .circle {
            position: relative;
            height: 3.14vw;
            width: 3.14vw;
            margin: auto auto auto 0;
            border-radius: 50%;
            border: 1px solid #FFE98B;
            display: flex;
            justify-content: center;
            font-size: 1.17vw;
        }
        .circle p {
            margin: auto;
        }

        .lang-row {
            display: flex;
            max-width: 13.84vw!important;
            flex: 0 0 13.84vw!important;
        }
        .col-lang {
            
            display: flex;
            justify-content: center;
        }

        .phone-nr {
            font-weight: 600;
            font-family: 'Montserrat';
            margin: auto auto auto 1vw;
            
        }
        .phone-nr a{
            color: #FFE898;
            font-size: 1.17vw;
            text-decoration: none;
        }
        .phone-nr a:hover {
            text-decoration: underline;
        }
        .button-col {
            max-width: 11.65vw;
            flex: 0 0 12vw;
            display: flex;
        }
        .white_mode {
            background-color: rgba(204, 204, 204, 0.89);
            color: #FFFFFF;
        }
        .white_link {
            color: #FFFFFF!important;
        }
        .white_point {
            background-color: #FFFFFF!important;
        }
        .white_lang {
            border: 2px solid white!important;
            color:white!important;
            background-color:#919191!important;
        }
        .white_2 {
            border: 2px solid white;
        }
        .fade-point-enter-active,
        .fade-point-leave-active {
            transition: opacity 0.1s ease-in-out transform 0.1s ease-in-out;
        }

        .fade-point-enter-from,
        .fade-point-leave-to {
            opacity: 0;
            transform: translateY(15px);
        }
        .v-enter-from {
            opacity: 0;
            transform: translateY(-20px); 
        }
        .v-leave-from,.v-leave-to {
            opacity: 0;
            transform: translateY(-20px); 
        }   
    }
</style>

