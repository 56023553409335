<template>

<full-page :options="options">
    
        <MenuComponent id="menu" :whiter="true"  />
        <SignUp id="signup"  />

        <TextFull class="section" :title='title_full' :desc='desc_full' :imgLink='img_full' translation="ortodonty" />
        <TextRight class="section" :title='title_right' :desc='desc_right' :imgLink='img_right' translation="ortodonty" />
        <TextNone class="section" :imgLink='img_fullscreen' translation="ortodonty" />
        <TextLeft class="section" :title='title_left' :desc='desc_left' :imgLink='img_left' translation="ortodonty" />

        <StickyContacts id="sticky" /> 
        <FooterComponent class="section" />
    
</full-page>
       
</template>

<script>
import MenuComponent from '../common/MenuComponent.vue'
import StickyContacts from '../common/StickyContacts.vue'

import TextFull from '@/common/Sections/TextFull.vue'
import TextRight from '@/common/Sections/TextRight.vue'
import TextNone from '@/common/Sections/TextNone.vue'
import TextLeft from '@/common/Sections/TextLeft.vue'

import FooterComponent from '../components/FooterComponent.vue'
import SignUp from '@/common/SignUp.vue'

export default {
    name: "OrtodontyView",
    mounted() {
        document.querySelector('.fp-watermark').style.display = 'none';
        window.fullpage_api.moveTo(1)
    },
    data() {
        return {
            options: {
                licenseKey: 'gplv3-license',
                fixedElements: '#menu,#signup,#sticky',
                navigation: true,
                navigationPosition:'left',
            },
            title_full: 'Ortodontie',
            desc_full:'Uită de dinți îngesuiți, aliniați incorect sau de o muscătură patologică cu PrimDent! La noi poți găsi  toate soluțiile  pentru îndreptarea dinților neregulați, corectarea problemelor cu mușcătura, îmbunătățirea aspectului feței și, desigur, crearea unui zâmbet sănătos  și frumos.',
            img_full:'/img/Ortodontie/main.png',
            title_right:'Ce soluții  găsești la PrimDent?',
            desc_right:'Noi vă  oferim  toate  soluțiile pentru  a recăpăta un zâmbet  sănătos cât  pentru  cei mici, atât  și pentru  adolescenți  și maturi. Vă  punem  la dispoziția dumneavoastră toată  gama de plăcuțe și  sisteme  de bracheți de la cei metalici  până  la cei de ceramică și  safir. Alegerea  corectă  o faceți  împreună cu medicul  ortodont după  o consultație preventivă  și o radiografie,fiindcă fiecare caz este individual și are indicațiile sale.',
            img_right:'/img/Ortodontie/left.png',
            title_left:'Alege bracheți  de la PrimDent!',
            desc_left:'Noi cu seriozitate alegem fiecare  membru al echipei noastre și cu plină  încredere vă putem încredința  sănătatea  zâmbetului  dumneavoastră în mâinele medicului nostru ortodont. Este un specialist bine pregătit,cu o experiență și un portofoliu de cazuri rezolvate cu succes  destul de vast. De asemenea  cu un bagaj de specializări și cursuri  naționale și internaționale care îl  ajută să  rezolve  cazurile  chiar  din cele mai provocatoare. Dacă  vrei  să  te convigi și tu, atunci apelează pentru o programare!',
            img_left:'/img/Ortodontie/right.png',
            img_fullscreen:'/img/Ortodontie/video.png',
        }
    },
    components: {
        MenuComponent,
        StickyContacts,

        TextFull,
        TextRight,
        TextNone,
        TextLeft,
        
        FooterComponent,
        SignUp
    },
}

</script>

<style>
@import '@/assets/css/global.css';
</style>

