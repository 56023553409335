<template>
    <div class="upper-contact">
            <div class="upper-content">
                <div class="contact-icon">
                    <img src="../assets/img/home.svg">
                </div>
                <div class="contact-title small-margin">
                    <p>{{$t('main_contact_title')}}</p>
                </div>
                <div class="contacts">
                    <div class="item item-50 small-margin">
                        <a class="item-link" href="tel: +373 79 19 19 41">{{$t('phone_number')}}</a>
                    </div>
                    <div class="item item-50 small-margin">
                        <a class="item-link" href="https://www.google.com/maps/place/Strada+Alecu+Russo+61%2F6,+Chi%C8%99in%C4%83u+2044/data=!4m2!3m1!1s0x40c97c8a2d261923:0xb92854f01044e996?sa=X&ved=2ahUKEwjkpoeI-9L4AhU5wQIHHYwiDRkQ8gF6BAgSEAE">{{$t('address')}}</a>
                    </div>
                    <div class="item item-60 big-margin">
                        <a class="item-link" href="mailto:primdent@mail.ru">primdent@mail.ru</a>
                    </div>
                    <div class="item-sm item-40 ">
                        <SmList />
                    </div>
                    <div class="item item-70 big-margin">
                        <div class="row dates-row">
                            <div class="col-6 underline-span">
                                {{$t('main_contact_weekday_1')}}
                            </div>
                            <div class="col-6 text-right">
                                {{$t('hours1')}}
                            </div>
                            <div class="col-6 underline-span">
                                {{$t('main_contact_weekday_2')}}
                            </div>
                            <div class="col-6 text-right">
                                {{$t('hours2')}}
                            </div>
                        </div>
                    </div>
                    <div class="item-button">
                        <ButtonComponent color="yellow" text="Programeaza-te"  data-bs-toggle="modal" data-bs-target="#signUp"  />
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import ButtonComponent from '../common/ButtonComponent.vue'
import SmList from '@/common/SmList.vue'

export default {
    name: "ContactComponent",
    components: {
        ButtonComponent,
        SmList
    },
    data() {
        return {
            title: 'Contacte',
            desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
        }
    }
}
</script>

<style>
    .upper-contact {
        position:relative;
        width: 100vw;
        height: 100%; 
        display:flex;
        justify-content: center;
        margin-top:0;
        background-color: rgba(28,68,55,1);
    }
    .upper-content {
        padding: 2vh 1vw 0vh 5vw;
        position:relative;
        margin: auto;
        width: 100vw;

    }
    .row {
        margin-left: auto;
        margin-right: auto;
    }
    .contact-icon {
        display: flex;
        justify-content: center;
    }
    .contact-icon img {
        width: 31px;
        height: 31px;
    }
    .contact-title p {
        text-align: center;
        font-size: 4.85vw;
        font-family: 'Montserrat';
        font-weight: 500;
        color:#FFF3C7;
        text-decoration: underline;
    }
    .contacts {
        display: flex;
        flex-wrap: wrap;
        transform: scale(0.9);
        height: 100%;
        margin-top: -5vh;
    }
    .item::before {
        content: '';
        width: 4.85vw;
        height: 1px;
        display: block;
        background-color: #FFF3C7;
        margin-bottom: 10px;
    }
    .item {
        font-size: 4.85vw;
        font-family: 'Montserrat';
        font-weight: 500;
        color:#FFF3C7;
    }
    .small-margin {
        margin-top: 2.34vh;
    }
    .big-margin {
        margin-top: 2.6vh;
    }
    .item-40 {
        width: 40%;
    }
    .item-50 {
        width: 50%;
    }
    .item-60 {
        width: 60%;
    }
    .item-sm {
        margin: auto auto 1% auto;
    }
    .item-70 {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    .item-link {
        color:#FFF3C7;
        text-decoration: none;
    }
    .item-link:hover {
        color:#FFF3C7;
        text-decoration: underline;
    }
    .dates-row .col-6{
        padding: 0;
        margin: 0;
    }   
    .underline-span {
        text-decoration: underline;
    }
    .item-button {
        margin: 15px auto 25px auto;
    }
    @media (min-width:600px) {
        .upper-content {
            margin: auto;
            padding: 0;
        }
        .contact-title p {
            font-size: 4vw;
        }
        .item {
            font-size: 4vw;
            text-align: center;
        }
    }

    @media (min-width:920px) {
        .item-button {
            display: none;
        }

        .upper-contact {
            width: 40vw;
            background-color: transparent;
            margin:auto 0 auto auto;
        }
        .contact-icon img {
            width:2.93vw;
            height: 2.93vw;
        }
        .contact-title p {
            font-family: 'Montserrat';
            font-size: 22px;
            font-weight: 600;
        }
        .small-margin {
            margin-top: 22.5px;
        }
        .big-margin {
            margin-top: 33.5px;
        }
        .item {
            font-size: 22px;
            font-weight: 300;
        }
        .item::before {
            width: 2.42vw;
        }
        .item-40 {
            width: 120px;
        }
        .item-70 {
            width: 80%;
         }
     }

    @media (min-width:1200px) {
        .item-button {
            display: none;
        }

        .upper-contact {
            width: 28vw;
            background-color: transparent;
            margin:auto 0 auto auto;
        }
        .contact-icon img {
            width:2.93vw;
            height: 2.93vw;
        }
        .contact-title p {
            font-family: 'Montserrat';
            font-size: 22px;
            font-weight: 600;
        }
        .small-margin {
            margin-top: 22.5px;
        }
        .big-margin {
            margin-top: 33.5px;
        }
        .item {
            font-size: 22px;
            font-weight: 300;
            text-align: left;
        }
        .item::before {
            width: 2.42vw;
        }
        .item-40 {
            width: 120px;
        }
        .item-70 {
            width: 80%;
         }
     }
</style>

