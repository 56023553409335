<template>

        <div class="section-container d-flex">
            <div class="image-section order-sm-1 order-lg-2" :style="backgroundStyles(image)"></div>
            <div class="text-section order-sm-2 order-lg-1 ">
                <div class="text-container-left">
                    <div class="text-medium text-center">
                        {{$t(translation + '_left_title')}}
                    </div>
                    <div class="text-small text-right line-28 margin-top-25" v-html="$t(translation + '_left_desc')">
                        
                    </div>
                    <div class="button-container margin-top-25">
                        <ButtonComponent color="yellow" :text="$t(translation + '_left_button')" data-bs-toggle="modal" data-bs-target="#signUp"  />
                    </div>
                </div>
            </div>
        </div>

</template>

<script>
import ButtonComponent from '@/common/ButtonComponent.vue'
import { Image } from '@/plugins/backend/images'
import { useWindowSize } from 'vue-window-size';

export default {
    name: "TextLeft",
    components: {
        ButtonComponent
    },
    setup() {
        const { width, height } = useWindowSize();
        return {
            windowWidth: width,
            windowHeight: height
        }
    },
    methods: {
        backgroundStyles(image) {
                return {
                    'background-image': `url(${image})`,
                }
        }
    },
    props: {
        imgLink: String,
        title: String,
        desc: String,
        translation: String
    },
    created() {
        if (this.windowWidth > 450) {
            Image.getImage(`${this.translation}_image4`).then(data => {
                this.image = this.$store+data;
            })    
        }
        else {
            Image.getImage(`${this.translation}_image4_mobile`).then(data => {
                this.image = this.$store+data;
            })
        }
        
    },  
    data() {
        return {
            image:'',
            mobile_image:'',
        }
    }
}
</script>

<style scoped>

@import '@/assets/css/global.css';
</style>

