<template>
    <div class="content">
        <div class="social-list-div">
             <ul class="social-list">
                <li class="social-item" v-bind:class="{sm_0: open, sm_1:!open}">
                    <a :href="$t('instagram')"><img src="../assets/img/ig_icon.svg"></a>
                </li>
                <li class="social-item" v-bind:class="{sm_0: open, sm_2:!open}">
                    <a :href="$t('viber')"><img src="../assets/img/viber.svg"></a>
                </li>
                <li class="social-item" v-bind:class="{sm_0: open, sm_3:!open}">
                    <a :href="$t('whatsapp')"><img src="../assets/img/whatsup.svg"></a>
                </li>
                <li class="social-item" v-bind:class="{sm_0: open, sm_4:!open}">
                    <a :href="$t('facebook')"><img src="../assets/img/messenger.svg"></a>
                </li>
            </ul>
        </div>
       
        <div class="circle">
            <img @click="changeState" class="circle-icon" src="../assets/img/message.svg">
        </div>
        <div class="go-up" @click="goUp" >
            <img class="go-up-icon" src="../assets/img/arrow_up.png">
        </div>
    </div>
</template>

<script>



export default {
    name: "StickyContacts",
    data() {
        return {
            open: false,
        }
    },
    methods: {
        goUp() {
            window.fullpage_api.moveTo(1)
        },
        changeState() {
            this.open=!this.open;
        }
    }
}
</script>

<style scoped>
    .content {
        position: fixed;
        z-index: 2;
        bottom: 35px;
        right: 5vw;
    }
    .social-list-div {
        text-align: center;
        display: flex;
        justify-content: center;
        position:relative;
    }
    .social-list {
        text-align: center;


    }
    .social-item {
        margin-top: 1vh;
        margin-bottom:1vh;
        position: relative;
        display: flex;
        justify-content: center;
        color:white;
         opacity: 0;
         transition: transform 0.75s, opacity 0.85s;
         z-index: 1;
                 transform: translateX(-50%);
        
    }
    .a img{
        margin: auto;

    }
    .sm_1 {
        transform: translateY(23vh);
    }
    .sm_2 {
        transform: translateY(18vh);
    }
    .sm_3 {
        transform: translateY(14vh);
    }
    .sm_4 {
        transform: translateY(7.5vh);
    }
    .sm_0 {
        transform: translateY(0vh);
        opacity: 1;
    }
    .circle {
        position: relative;
        display: flex;
        justify-content: center;
        width: 59px;
        height: 59px;
        border-radius: 50%;
        margin: auto;
        z-index: 2;
        background-color:#1C4437;
        cursor: pointer;
        box-shadow: 0 0 0 0 rgba(255,233,139,1);
        animation: pulse-yellow 3s infinite;
    }
    .circle-icon {
        width: 50%;
        height: 50%;
        margin: auto;
    }
    .go-up {
        display: flex;
        justify-content: center;
        width: 36px;
        height: 36px;
        background-color: #414141;
        border-radius: 50%;
        margin: 2.7vh auto auto auto;
        cursor: pointer;
        position:relative;
        z-index: 10;
    }
    .go-up-icon {
        margin: auto;
    }
    @keyframes pulse-yellow {
        0% {
            box-shadow:  0 0 0 0  rgba(255,233,139,0.7);
        }
        
        40% {
            box-shadow:  0 0 0 18px rgba(255,233,139,0);
        }
        
        100% {
            box-shadow:  0 0 0 0 rgba(255,233,139,0);
        }
    }
</style>

