<template>

    <full-page :options="options">
      
            <MenuComponent id="menu" :whiter="true"  />
            <SignUp id="signup"  />
    
            <TextFull class="section" :title='title_full' :desc='desc_full' :imgLink='img_full'  translation="implantology"/>
            <TextRight class="section" :title='title_right' :desc='desc_right' :imgLink='img_right' translation="implantology" />
            <TextNone class="section" :imgLink='img_fullscreen' :isVideo="true" translation="implantology" />
            <TextLeft class="section" :title='title_left' :desc='desc_left' :imgLink='img_left' translation="implantology" />
    
            <StickyContacts id="sticky" /> 
            <FooterComponent class="section" />
        
    </full-page>
           
    </template>
    
    <script>
    import MenuComponent from '../common/MenuComponent.vue'
    import StickyContacts from '../common/StickyContacts.vue'
    
    import TextFull from '@/common/Sections/TextFull.vue'
    import TextRight from '@/common/Sections/TextRight.vue'
    import TextNone from '@/common/Sections/TextNone.vue'
    import TextLeft from '@/common/Sections/TextLeft.vue'
    
    import FooterComponent from '../components/FooterComponent.vue'
    import SignUp from '@/common/SignUp.vue'
    
    export default {
        name: "ImplantologyView",
        mounted() {
            document.querySelector('.fp-watermark').style.display = 'none';
            window.fullpage_api.moveTo(1)
        },
        updated(){
            
        },
        data() {
            return {
                options: {
                    licenseKey: 'gplv3-license',
                    fixedElements: '#menu,#signup,#sticky',
                    navigation: true,
                    navigationPosition:'left',
                },
                title_full: 'Implantologie',
                desc_full:'Ai nevoie de un implant dentar?  În clinica stomatologică PrimDent beneficiezi de cea mai eficientă soluție de înlocuire a dinților lipsă – implanturile dentare, care fac parte din top 3 din lume după calitate și rezistență în timp. Un implant dentar are rolul de a reda aspectul estetic și funcțional similar dinților naturali, devenind un tratament de elecție pentru redobândirea unei masticații eficiente și a unui aspect plăcut.',
                img_full:'/img/Implantologie/main.png',
                title_right:'De ce alegi  implantul dentar de la PrimDent?',
                desc_right:'Un implant dentar reprezintă, la ora actuală, cea mai bună alternativă de înlocuire a dinților lipsă, punând accent atât la capitolul durabilitate, cât și la capitolul confort. Pentru pacienții care se confruntă cu problema edentației (pierderea parțială sau totală a dinților),  Clinica stomatologica PrimDent oferă cele mai noi tehnologii de implantare dentară, astfel încât zâmbetul să fie reconstruit într-un timp record și siguranță maximă. Tratamentul cu implanturi dentare înlocuiește cu succes proteza dentară mobilă cu care eram obișnuiți până nu demult. Noi punem accent pe calitatea și siguranța pacienților în timpul intervențiilor chirurgicale. De aceea optăm pentru cele mai sigure metode de sterilizare a instrumentarului stomatologic.',
                img_right:'/img/Implantologie/left.png',
                title_left:'Noi avem  soluții  chiar  pentru cazuri complicate!',
                desc_left:'În cazul insuficienței de os, în incinta Clinicii stomatologice PrimDent puteți efectua operația de creștere osoasă. Adiția (creșterea) osoasă este o procedură chirurgicală folosită pentru a reface osul pierdut și pentru a induce formarea de os nou. Scopul adiției osoase este pentru a oferi o bază solidă viitoarelor implanturi dentare.  Această procedură se realizează pentru a îndeplini condițiile pentru instalarea corectă și de înaltă calitate a implanturilor dentare, cu o recuperare rapidă. Cu noi vei obține zâmbetul dorit, o masticație corectă și sănătate orală! Suntem acea clinică dentară în care tratamentele stomatologice și de implantologie de ultimă generație schimbă viețile celor care ne pășesc pragul. Te așteptăm!',
                img_left:'/img/Implantologie/right.png',
                img_fullscreen:'/img/Implantologie/video.mp4',                
            }
        },
        components: {
            MenuComponent,
            StickyContacts,
    
            TextFull,
            TextRight,
            TextNone,
            TextLeft,
            
            FooterComponent,
            SignUp
        },
    }
    
    </script>
    
    <style>
    @import '@/assets/css/global.css';
    </style>
    
    