<template>
    <div class="content" :style="backgroundStyles(image)" v-bind:class="{dark:darken}">
        <div class="main-div row">
                <div class="col-12 d-flex justify-content-center">   
                    <p class="main-text-p">{{title}}</p>
                </div>
                
                <div class="col-12 d-flex justify-content-center"><router-link :to="'/'+adress"><ButtonComponent color="white" :text="$t('buttons_find_out_more')"  /></router-link></div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from './ButtonComponent.vue'
import { Image } from '@/plugins/backend/images'
import { useWindowSize } from 'vue-window-size';

export default {
    name: "FullscreenView",
    props: {
       title: String,
       imgLink:String,
       btnText:String,
       darken: {
            type: Boolean,
            default:false
       },
       adress:String,
       nr:Number,
    },
    components: {
        ButtonComponent
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            image:''
        }
    },
    setup() {
        const { width, height } = useWindowSize();
        return {
            windowWidth: width,
            windowHeight: height
        }
    },
    created() {
        if (this.windowWidth > 450) {
            Image.getImage(`services_image${this.nr}`).then(data => {
                this.image = this.$store+data;
            })
        }
        else {
            Image.getImage(`services_image${this.nr}_mobile`).then(data => {
                this.image = this.$store+data;
            })
        }
    },
    methods: {
        backgroundStyles(image) {
            return {
                'background-image': `url(${image})`,
            }
        }
    }
}
</script>

<style scoped>
    .content {
        z-index: 1;
        background-position:center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100vw;
        display: flex;
      
    }
        .main-div {
        margin: auto;
        position: relative;
         width: 66.4vw;
    }
    .main-text-p {
        font-size: 7.45vw;
        font-weight: 600;
        font-family: 'Montserrat';
        color:white;
    }
    .desc-text-p {
        font-family: 'Montserrat';
        font-size: 0.81rem;
        color:white;
        text-align: center;
        padding: 0 5vw 0 5vw;
    }
    .dark {
        box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.25);
    }

    @media screen and (min-width:920px){
        .main-text-p {
            font-size: 3.65vw;
        }
    }
</style>

