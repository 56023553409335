<template>
    <div class="content">
        <ul class="footer-list row">
            <li class="copyright footer-list-item col-xs-12 col-lg-2">PrimDent © 2022
            </li>
            <li class="footer-list-item col-xs-12 col-lg-2" @mouseover="showf_1 = true" @mouseleave="showf_1=false"><router-link to="/services">{{$t('footer_col1')}}</router-link>
                <Transition name="fade-point">
                    <div v-if="showf_1" class="point"></div>
                </Transition>
            </li>
            <li class="footer-list-item col-xs-12 col-lg-2" @mouseover="showf_2 = true" @mouseleave="showf_2=false"><a href="#" data-bs-toggle="modal" data-bs-target="#signUp">{{$t('footer_col2')}}</a>
                <Transition name="fade-point">
                    <div v-if="showf_2" class="point"></div>
                </Transition></li>
            <li class="footer-list-item col-xs-12 col-lg-2" @mouseover="showf_3 = true" @mouseleave="showf_3=false"><a href="#" @click="updatee">{{$t('footer_col3')}}</a>
                <Transition name="fade-point">
                    <div v-if="showf_3" class="point"></div>
                </Transition></li>
            <li class="footer-list-item col-xs-12 col-lg-2" @mouseover="showf_4 = true" @mouseleave="showf_4=false" @click="goUp"><a href="#">{{$t('footer_col4')}}</a>
                <Transition name="fade-point">
                    <div v-if="showf_4" class="point"></div>
                </Transition>
            </li>
            <li class="footer-list-item footer-list-sm col-xs-12 col-lg-2">
                <SmList />
            </li>
        </ul>
        <Dialog v-model:visible="showTos" @after-hide="this.showTos=false">
            <template #header>
                    <h3>{{$t('tos_title')}}</h3>
            </template>
           
            <template #footer>
                <div class="" v-html="$t('tos_desc')">
                        
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import SmList from '@/common/SmList.vue'


export default {
    name: "FooterComponent",
    components: {
        SmList,
    },
    data() {
        return {
            title: 'Turism Dentar',
            desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
            showf_1:false,
            showf_2:false,
            showf_3:false,
            showf_4:false,
            showTos:false
        }
    },
    methods: {
        goUp() {
            window.fullpage_api.moveTo(4)
        },
        updatee() {
            this.showTos = true
        }
    },
}
</script>

<style scoped>
    .content {
        position: relative;
        display: block;
        clear:both;
        background-color: #1C4437;
        height: 40%;
        max-height: 360px;
        width: 100vw;
        overflow: hidden;
        
    }
    .footer-list {
        height: 100%;
        padding: 7%;
        color:#FFE898;
        font-family: 'Montserrat';
        font-weight: 600;
    }
    .footer-list-item {
       font-size: 3.38vw;
       display: flex;
       justify-content: center;
       margin: auto;
    }
    .footer-list-item a {
        color:#FFE898;
    }
     .copyright {
        font-size: 3.86vw;
        font-weight: 800;
    }
    .footer-list-sm {
        display: block;
        position: relative;
        width:55%;
    }
    @media only screen and (min-width:600px) {
        .copyright {
            font-size: 2.5vw;
            margin-top: 2vh;
        }
        .footer-list {
            padding:0;
            width:75vw;
            font-size: 2vw;
        }
        .footer-list-sm {
            width:65%;
        }
        .footer-list-item {
            font-size: 2.5vw;
        }
     }
    @media only screen and (min-width:920px) {
        .content {
            min-height: 143px; 
            height: 18.62vh;
        }
        .footer-list {
            padding:0;
            width:85vw;
        }
        .footer-list-sm {
            width:10vw;
        }
        .footer-list-item {
            font-size: 1vw;
            
        } 
        .copyright {
            font-size: 1.1vw;
            margin-top: auto;
        } 
        .point {
            position: absolute;
            display:block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color:#FFE898;
            transform: translateY(35px);
            left: 50%;
            opacity: 1;
            transition: 0.1s ease-in-out;
        }
        .fade-point-enter-active,
        .fade-point-leave-active {
            transition: opacity 0.1s ease-in-out transform 0.1s ease-in-out;
         }

        .fade-point-enter-from,
        .fade-point-leave-to {
            opacity: 0;
            transform: translateY(45px);
        }
        .sm-list {
            width:100%;
        }
     }
     @media only screen and (min-width:1200px) {
        .footer-list {
            width:75vw;
        }
        .sm-list {
            width:80%;
        }
     }
</style>

