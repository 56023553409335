<template>

<full-page :options="options">
    
        <MenuComponent id="menu" :whiter="true"  />
        <SignUp id="signup"  />

        <TextFull class="section" :title='title_full' :desc='desc_full' :imgLink='img_full' translation="prosthetics" />
        <TextRight class="section" :title='title_right' :desc='desc_right' :imgLink='img_right' translation="prosthetics"/>
        <TextNone class="section" :imgLink='img_fullscreen' :isVideo="true" translation="prosthetics" />
        <TextLeft class="section" :title='title_left' :desc='desc_left' :imgLink='img_left' translation="prosthetics" />
        <TextRight class="section" :title='title_right2' :desc='desc_right2' :imgLink='img_right2' translation="prosthetics" />

        <StickyContacts id="sticky" /> 
        <FooterComponent class="section" />
    
</full-page>
       
</template>

<script>
import MenuComponent from '../common/MenuComponent.vue'
import StickyContacts from '../common/StickyContacts.vue'

import TextFull from '@/common/Sections/TextFull.vue'
import TextRight from '@/common/Sections/TextRight.vue'
import TextNone from '@/common/Sections/TextNone.vue'
import TextLeft from '@/common/Sections/TextLeft.vue'

import FooterComponent from '../components/FooterComponent.vue'
import SignUp from '@/common/SignUp.vue'

export default {
    name: "ProstheticsView",
    mounted() {
        document.querySelector('.fp-watermark').style.display = 'none';
        window.fullpage_api.moveTo(1)
        window.scrollTo({left: 100,behavior: 'smooth'});
    },
    data() {
        return {
            options: {
                licenseKey: 'gplv3-license',
                fixedElements: '#menu,#signup,#sticky',
                navigation: true,
                navigationPosition:'left',
            },
            title_full: 'Protetică',
            desc_full:'Cu noi îți poți reface aspectul dinților prin lucrări fixe: fațete, coroane și punți dentare, precum și prin lucrări mobile sau mobilizabile: proteze totale sau parțiale, proteze scheletate cu sisteme speciale, coroane și proteze fixe pe implanturi. La PrimDent poți găsi orice soluție pentru a-ți schimba aspectul  zâmbetului  sau pentru a înlocui unul sau mai mulți dinți lipsă, atunci când  nu este posibilă implantarea dentară.',
            img_full:'/img/Prostetica/main.png',
            title_right:'Coroanele dentare',
            desc_right:'Atunci când avem unul sau mai mulți dinți lipsă, punțile dentare sunt o alternativă potrivită de a înlocui spațiile goale.  Dar ați știut că se recomanda acoperirea dintelui cu coroană dentară dacă 50% din suprafața lui este construit din obturație?! Este adevărat că o plomba masivă  nu poate suporta forța masticactorie un timp îndelungat și de acesta are loc adesea facturarea dintelui.',
            img_right:'/img/Prostetica/left.png',
            title_left:'Coroanele din zirconiu ',
            desc_left:'Oxidul de Zirconiu este cel mai rezistent tip de material dentar disponibil. Este de 5 ori mai rezistent decât porțelanul din lucrările metalo-ceramice clasice. Zirconiu este foarte rezistent la forțele masticatorii și uzură, fără risc de factură, are o culoare albă și o transluciditate asemănătoare dinților naturali, ceea ce îl face și un material  foarte estetic. Dacă și tu îți dorești o dantură frumoasă, cu care să poți zâmbi fără teamă și cu ajutorul căreia să poți mânca tot ce îți dorești, atunci coroanele dentare din zirconiu cu ceramică sunt o opțiune potrivită pentru tine! ',
            img_left:'/img/Prostetica/right.png',
            img_fullscreen:'/img/Prostetica/video.mp4',
            title_right2:'Coroanele pe implanturi ',
            desc_right2:'Funcționalitatea și estetica danturii poate fi redată și cu ajutorul coroanelor dentare realizate pe implanturi dentare. Acestea asigură reușita unui rezultat excepțional reprezentat de un zâmbet frumos . Acest tip de coroane dentare se utilizează pentru restaurarea dinților frontali sau/și ai celor laterali în scopul acoperirii implanturilor dentare, înlocuind cu exactitate dinții  naturali. Acest tip de coroană este foarte popular atunci când ai lipsa a unui sau mai mulți dinți. De asemenea când ai lipsa totală de dinți, poți opta pentru o proteză fixă pe 4 sau 6 implanti care exclude disconfortul protezelor mobile, dar totodată reface în totalitate funcția masticatorie și partea estetica a danturii dumneavoastră.',
            img_right2:'/img/Prostetica/left2.png',
        }
    },
    components: {
        MenuComponent,
        StickyContacts,

        TextFull,
        TextRight,
        TextNone,
        TextLeft,
        
        FooterComponent,
        SignUp
    },
}

</script>

<style>
@import '@/assets/css/global.css';
</style>

