<template>
    <div class="button" :class="[{fw:fullwidth},color]">
      <p> {{text}}</p>
    </div>
</template>

<script>



export default {
    data() {
        return {

        }
    },
    props: {
        color: String,
        text: String,
        fullwidth: {
            type:Boolean,
            default:false,
        }
    },  
    computed: {
        style() {
             return { "background-color": this.bgColor};
        }
    },  
    name: "ButtonCompnent",
}
</script>

<style scoped>
    .button {
        min-width: 45vw;
        height: 5.5vh;
        min-height: 49px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        margin-top: 1.5vh;
        cursor: pointer;
    }
    .button p {
        margin: auto;
        font-family: 'Montserrat';
        font-weight: bold!important;
        font-size: 3.38vw;
        text-align: center;
    }
    .yellow {
        background-color: #FFE98B;
        color:#1C4437;
    }
    .green {
        background-color:#1C4437;
        color:#FFE98B;
    }
    .white {
        background-color:#FFFFFF;
        color:#919191;
    }
    .fw {
            width:100%!important;
    }
    @media screen and (min-width:600px) {
        .button {
            height: 7vh;
        }
        .button p {
            font-size: 1.5rem;
        }
    }
    @media screen and (min-width:920px) {
        
        .button {
            min-width: 13vw;
            width: 13vw;
            margin:auto;
            height: 8vh;
            min-height: 49px;
        }
        .button p {
            font-size: 1.35vw;
        }
        
    }
    @media screen and (min-width:1200px) {
        
        .button {
            min-width: 13vw;
            width: 13vw;
            margin:auto;
            height: 6.3vh;
            min-height: 49px;
        }
        .button p {
            margin: auto;
            font-family: 'Montserrat';
            font-weight: bold;
            font-size: 1.02vw;  
            text-align: center;  
        }
        
    }
</style>

