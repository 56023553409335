<template>

<full-page :options="options">
  
        <MenuComponent id="menu" :whiter="true" />
        <SignUp id="signup"  />

        <TextFull class="section" :title='title_full' :desc='desc_full' :imgLink='img_full' translation="estetica" />
        <TextRight class="section" :title='title_right' :desc='desc_right' :imgLink='img_right' translation="estetica" />
        <TextNone class="section" :imgLink='img_fullscreen' :isVideo="true"  translation="estetica" />
        <TextLeft class="section" :title='title_left' :desc='desc_left' :imgLink='img_left' translation="estetica" />

        <StickyContacts id="sticky" /> 
        <FooterComponent class="section" />

</full-page>
       
</template>

<script>
import MenuComponent from '../common/MenuComponent.vue'
import StickyContacts from '../common/StickyContacts.vue'

import TextFull from '@/common/Sections/TextFull.vue'
import TextRight from '@/common/Sections/TextRight.vue'
import TextNone from '@/common/Sections/TextNone.vue'
import TextLeft from '@/common/Sections/TextLeft.vue'

import FooterComponent from '../components/FooterComponent.vue'
import SignUp from '@/common/SignUp.vue'

export default {
    name: "EsteticView",
    mounted() {
        document.querySelector('.fp-watermark').style.display = 'none';
        window.fullpage_api.moveTo(1)
    },
    data() {
        return {
            options: {
                licenseKey: 'gplv3-license',
                fixedElements: '#menu,#signup,#sticky',
                navigation: true,
                navigationPosition:'left',
            },
            title_full: 'Estetică',
            desc_full:'Estetica dentară s-a născut din dorința oamenilor de a avea un zâmbet cît mai frumos , natural și pe termen lung. La PrimDent creăm și recreăm cele mai naturale și mai frumoase zâmbete, ale căror rezultate nu sunt doar estetice, ci și funcționale, cu o rezistență îndelungată. Cu ajutorul tehnologiilor digitale de ultimă generație care proiectează zâmbete în 3D și datorită faptului că suntem certificați internațional în tehnică dentară, am reușit să creăm cele mai naturale coroane și fațete dentare, cu cel mai înalt grad de puritate, perfect integrate cu structura dintelui natural și rezistente în timp. Pentru că știm că pacienții PrimDent nu își doresc doar un serviciu sau o procedură standard, ci o soluție de a-și îmbunătăți calitatea vieții!',
            img_full:'/img/Estetica/main.png',
            title_right:'Albirea Dinților',
            desc_right:'1. Albirea dinților este primul pas în estetica dentară. Dorești un zâmbet: -mai alb cu până la 4 nuanțe? -în doar 10 minute? -fără durere și sensibilitate? -fără protecție  gingivala? În clinica noastră poți beneficia de toate acestea cu lampa profesională  de ultimă generație BlancOne, marca înregistrată IDS SPA, Italia. Procedura  se efectuează  cu gel special care nu conține peroxid de hidrogen și astfel  nu provoacă sensibilitate dentară absolut deloc. După albirea dentară, urmați  indicațiile medicului pentru a menține cât mai îndelungat rezultatul procedurii.',
            img_right:'/img/Estetica/left.png',
            title_left:'Fațete dentare',
            desc_left:'2. Pacienții care își doresc un rezultat mai spectaculos pot opta  pentru fațete dentare. Fațetele dentare sunt plăcuțe subțiri din porțelan,care acoperă  doar partea exterioară, vizibilă a dinților, dându-le astfel  un aspect mult mai îmbunătățit fiind la fel de puternice ca și smalțul natural. Este soluția minim – invazivă în corectarea problemelor inestetice ale danturii. În clinica PrimDent pot fi realizate din ceramică, zirconiu  și E-max. Ce vei primi la finele tratamentului: -Aspectul natural al zâmbetului  -Armonizarea trăsăturilor fieții -Rezultat imediat și fără durere -Implicarea direct  în alegerea culorii și formei dinților -Garanție și certificate de asumare a calității!',
            img_left:'/img/Estetica/right.png',
            img_fullscreen:'/img/Estetica/video.mp4',
        }
    },
    components: {
        MenuComponent,
        StickyContacts,

        TextFull,
        TextRight,
        TextNone,
        TextLeft,
        
        FooterComponent,
        SignUp
    },
}

</script>

<style>
@import '@/assets/css/global.css';
</style>

