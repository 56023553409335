<template>
    <div class="content">
        <video v-if="video!=''" preload="metadata" muted autoplay loop style="pointer-events: none;" id="myVideo">
             <source :src="video" type="video/webm">
             <source :src="video" type="video/mp4">
        </video>    
        <div class="main-div row">
            <div class="col-12 d-flex justify-content-center">   
                <p class="main-text-p"> {{$t('main_banner_title')}}</p>
            </div>
            <div class="button-div row justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-3 d-flex justify-content-center"><router-link to="/about"><ButtonComponent color="yellow" :text="$t('main_banner_button_1')" /></router-link></div>
                <div class="col-sm-12 col-md-12 col-lg-3 d-flex justify-content-center"><a href="https://www.google.com/maps/place/Strada+Alecu+Russo+61%2F6,+Chi%C8%99in%C4%83u+2044/data=!4m2!3m1!1s0x40c97c8a2d261923:0xb92854f01044e996?sa=X&ved=2ahUKEwjkpoeI-9L4AhU5wQIHHYwiDRkQ8gF6BAgSEAE"><ButtonComponent color="green"  :text="$t('main_banner_button_2')" /></a></div>
            </div>
        </div>
        
    </div>
</template>

<script>    
import ButtonComponent from '../common/ButtonComponent.vue'
import { Image } from '@/plugins/backend/images'
export default {
    name: "MainSection",
    data() {
        return {
            video:''
        }
    },
    components: {
        ButtonComponent
    },
    created() {
        Image.getImage(`main_section_video`).then(data => {
            this.video = this.$store+data;
        })
    }
}
</script>

<style scoped>
    .content {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100vw;
        display: flex;
    }
    #myVideo {
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        margin: auto;
        position: absolute;
        right: 0;
        z-index: -1;
        bottom: 0;
        transform: translateX(-50%);
        left: 50%;
        min-width: 100%;
        min-height: 100%;
    }
    .main-div {
        margin: auto;
        position: relative;
    }
    .main-text-p {
        font-size: 7.45vw;
        font-weight: 600;
        font-family: 'Montserrat';
        color:white;
        text-align: center;
    }

    @media screen and (min-width:950px) {
        .main-text-p {
            font-size: 4.5vw;
        }
        .button-div {
            width: 100%;
            margin: auto;
        }
    }
    @media screen and (min-width:1200px) {
        .main-text-p {
            font-size: 3.65vw;
        }
        .button-div {
            width: 80%;
            margin: auto;
        }
    }
</style>

