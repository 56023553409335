<template>
    <div v-if="isVideo">
        <div class="video-container">
              <video v-if="this.video!=''"  preload="metadata" muted autoplay loop style="pointer-events: none;" id="sectionVideo" class="video-overlay">
                <source :src="video" type="video/webm">
                <source :src="video" type="video/mp4">
              </video>
              <div class="text-container">
                <div class="row">
                <div class="col-12 d-flex justify-content-center">   
                    <p class="text-big white">{{$t(translation + '_main_title')}}</p>
                </div>
                <div class="col-12 d-flex justify-content-center">   
                    <p class="text-small text-center white" v-html="$t(translation + '_main_desc')"></p>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div class="button-container margin-top-32">
                        <ButtonComponent color="yellow" :text="$t(translation + '_main_button')" data-bs-toggle="modal" data-bs-target="#signUp"/>
                    </div>
                </div>
                </div>
               </div>
        </div>
    </div> 
    <div v-else class="fh overlay" :style="backgroundStyles(image)">
        <div class="text-container">
            <div class="row">
            <div class="col-12 d-flex justify-content-center">   
                <p class="text-big white">{{$t(translation + '_main_title')}}</p>
            </div>
            <div class="col-12 d-flex justify-content-center">   
                <p class="text-small text-center white" v-html="$t(translation + '_main_desc')"></p>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <div class="button-container margin-top-32">
                    <ButtonComponent color="yellow" :text="$t(translation + '_main_button')" data-bs-toggle="modal" data-bs-target="#signUp"/>
                </div>
            </div>
           </div>
        </div>
        
    </div>
</template>

<script>

import ButtonComponent from '@/common/ButtonComponent.vue'
import { Image } from '@/plugins/backend/images'
import { useWindowSize } from 'vue-window-size';

export default {
    name: "TextFull",
    components: {
        ButtonComponent
    },
    props: {
        isVideo: {
            type: Boolean,
            default:false,
        },
        title: String,
        desc: String,
        imgLink: String,
        translation: String
    },
    setup() {
        const { width, height } = useWindowSize();
        return {
            windowWidth: width,
            windowHeight: height
        }
    },
    data() {
        return {
            image:'',
            mobile_image:'',
            video:''
        }
    },
    created() {
        if (this.isVideo == true){
            Image.getImage(`${this.translation}_video1`).then(data => {
                this.video = this.$store+data;
                console.log(this.video)
            })
        }
        else{
            if (this.windowWidth > 450) {
                Image.getImage(`${this.translation}_image1`).then(data => {
                    this.image = this.$store+data;
                })
            }   
            else {
                Image.getImage(`${this.translation}_image1_mobile`).then(data => {
                    this.image = this.$store+data;
                })
            }
            
        }   
        
        //console.log(this.image);
    },
    methods: {
        backgroundStyles(image) {
                return {
                    'background-image': `url(${image})`,
                }
        }
    }
}
</script>

<style>
@import '@/assets/css/global.css';
</style>

