import { createApp } from 'vue'

import App from './App.vue'


import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import {router} from './router'

import 'vue-fullpage.js/dist/style.css'
import VueFullPage from 'vue-fullpage.js'
import i18n from './i18n'
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import "primeicons/primeicons.css";
           
const app = createApp(App)
app.config.globalProperties.$loadedTranslations = true

app
.use(VueTelInput)
.use(VueFullPage)
.use(i18n)
.use(PrimeVue)
.use(router)


app.component('Dialog', Dialog);
app.config.globalProperties.$store = 'https://cms.primdent.md';
if (localStorage.language) {
    app.config.globalProperties.$language = localStorage.language;
}
else {
    localStorage.language = 0
    app.config.globalProperties.$language=0;
}


app.mount('#app')