<template>
    <ul class="sm-list row justify-content-center">
        <li class="col-lg-4 col-sm-2 col-3 smitem">
            <a :href="$t('facebook')"><img class="icon-img" src="../assets/img/facebook.svg"></a>
        </li>
        <li class="col-lg-4 col-sm-2 col-3 smitem">
            <a :href="$t('instagram')"><img class="icon-img icon-img-2" src="../assets/img/instagram.svg"></a>
        </li>
        <li class="col-lg-4 col-sm-2 col-3 smitem">
            <a :href="$t('youtube')"><img class="icon-img" src="../assets/img/youtube.svg"></a>
        </li>
    </ul>
</template>

<script>

export default {
    name: "SmList",
    components: {

    },
    data() {
        return {
            title: 'Turism Dentar',
            desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
        }
    }
}
</script>

<style scoped>
   .sm-list {
        margin: auto;
   }
   .smitem {
        display:flex;
        justify-content: center;
   }
   a img {
        margin: auto;
   }
   .icon-img {
        vertical-align: middle;
   }
   .icon-img-2 {
        margin-left: 1.43vw;
        margin-right: 1.43vw;
   }
   @media screen and (min-width:600px) and (max-width:900px) {
        .icon-img {
            width: 30px;
        }
   }
</style>

