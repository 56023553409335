import {api} from "@/plugins/axios/axios";


async function getImage(image) {
    const response = await api.get(`images/${image}`);
    return response.data;   
}

export const Image = {
    getImage,
}