import { api } from "@/plugins/axios/axios";

async function sendMail(data) {
    const response = await api.post(`/sendmail`, {data:data, 'headers':{'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}});
    console.log(response.data)
    return response.data;
}

export const Mail = {
    sendMail
}   