import {api} from "@/plugins/axios/axios";


async function getTranslations() {
    const response = await api.get(`dictionary/translations`);
    return response.data;
}

async function convertTranslations(translations) {
    const messages = {};
    let keys = ['ro','ru','en'];
    for (const translation of translations) {
      for (const language of keys) {
            if (!messages[language]) messages[language] = {};

            if (translation.locale == language )messages[language][translation.setting_id] = translation.value;
        }
    }
    return messages;
}
  
const locales = {
    ro: "ro",
    en: "en",
    ru: "ru",
};

export const Translation = {
    convertTranslations,
    getTranslations,
    locales: locales
}