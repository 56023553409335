<template>

<full-page :options="options">
  
        <MenuComponent id="menu" :whiter="true"  />
        <SignUp id="signup"  />

        <TextFull class="section" :title='title_full' :desc='desc_full' :imgLink='img_full' :isVideo="true" translation="pediatrics" />
        <TextRight class="section" :title='title_right' :desc='desc_right' :imgLink='img_right' translation="pediatrics" />
        <TextNone class="section" :imgLink='img_fullscreen' translation="pediatrics" />
        <TextLeft class="section" :title='title_left' :desc='desc_left' :imgLink='img_left' translation="pediatrics" />


        <StickyContacts id="sticky" /> 
        <FooterComponent class="section" />
    
</full-page>
       
</template>

<script>
import MenuComponent from '../common/MenuComponent.vue'
import StickyContacts from '../common/StickyContacts.vue'

import TextFull from '@/common/Sections/TextFull.vue'
import TextRight from '@/common/Sections/TextRight.vue'
import TextNone from '@/common/Sections/TextNone.vue'
import TextLeft from '@/common/Sections/TextLeft.vue'

import FooterComponent from '../components/FooterComponent.vue'
import SignUp from '@/common/SignUp.vue'

export default {
    name: "PediatricsView",
    mounted() {
        document.querySelector('.fp-watermark').style.display = 'none';
        window.fullpage_api.moveTo(1)
    },
    data() {
        return {
            options: {
                licenseKey: 'gplv3-license',
                fixedElements: '#menu,#signup,#sticky',
                navigation: true,
                navigationPosition:'left',
            },
            title_full: 'Pediatrie',
            desc_full:'Noi credem în Zâna Măseluță! Avem soluții pentru tratamentul cât a dinților de lapte , atât a dinților permanenți la copii și adolescenți. În clinica noastră puteți benificia de plombe dentare, tratament de canale, acoperirea dinților cu coroane și extracția dinților temporari.',
            img_full:'/img/Pediatrie/main.mp4',
            title_right:'Ajută  copilul  tău să  meargă  la stomatolog!',
            desc_right:'Prima impresie a copilului este de obicei cea mai puternică, prin urmare, este atât de important ca prima cunoștință a copilului cu medicul stomatolog să fie calmă și pozitivă. Clinica stomatologică PrimDent, este dotată cu tot necesarul, pentru ca cel mic să fie înconjurat de o atmosferă relaxantă, iar medicii noștri vor stabili contactul cu copii de orice vârstă și vor transforma tratamentul într-un joc distractiv și educativ.',
            img_right:'/img/Pediatrie/left.png',
            title_left:'Ce tratament oferim copiilor?',
            desc_left:'Specialiștii noștri cu drag vă vor ajuta cu orice tip de tratament pediatric, cum ar fi: 1. Tratamentul diților de lapte, plombe si tratarea rădăcinii; 2. Igiena profesională în cabinetul stomatologic; 3. Extracția dinților de lapte; 4.  Îndreptarea dinților cu ajutorul sistemului de bracket. Ai grijă de dantura copilului tău și fă o programare pentru un control!',
            img_left:'/img/Pediatrie/right.png',
            img_fullscreen:'/img/Pediatrie/video.png',
        }
    },
    components: {
        MenuComponent,
        StickyContacts,

        TextFull,
        TextRight,
        TextNone,
        TextLeft,
        
        FooterComponent,
        SignUp
    },
}

</script>

<style>
@import '@/assets/css/global.css';
</style>

